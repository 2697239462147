import React, { useState } from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { apiRequest } from '../../utils/Helpers';

import logo from '../../assets/logo.webp';
import { useParams } from 'react-router-dom';


interface ResetPasswordValues {
  pwd: string;
  pwdCheck: string;
}
interface ResetPasswordParams {
  token: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    scale: 0.7,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();

  const { token } = useParams<ResetPasswordParams>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const initialValues: ResetPasswordValues = {
    pwd: '',
    pwdCheck: '',
  };

  const resetHandler = async (values: ResetPasswordValues) => {
    try {
      setError('');
      setSuccess(false);
      const params = Object.assign({}, values, { token });
      await apiRequest(
        process.env.REACT_APP_API_URL + '/auth/reset',
        'POST',
        false,
        params
      );
      setSuccess(true);
    } catch (err: any) {
      setError(err.message ?? 'Un problème inconnu s\'est produit');
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Link href="/" title="Revenir à l'accueil">
          <img src={logo} alt="Logo Good Place" className={classes.logo} />
        </Link>

        <Typography component="h1" variant="h5">Changement du mot de passe</Typography>

        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: any = {};
            if (!values.pwd) {
              errors.pwd = 'Champ requis';
            } else if (!values.pwdCheck) {
              errors.pwdCheck = 'Champ requis';
            } else if (values.pwd !== values.pwdCheck) {
              errors.pwdCheck = 'Le mot de passe ne correspond pas à la confirmation'
            }
            return errors;
          }}
          onSubmit={(values) => resetHandler(values)}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                id="pwd"
                type="password"
                name="pwd"
                label="Mot de passe"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <br />

              <Field
                component={TextField}
                id="pwdCheck"
                type="password"
                name="pwdCheck"
                label="Confirmation mot de passe"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <br />

              {error !== '' ?
                <Alert severity="error">{error}</Alert>
              : null}
              {success ?
                <Alert severity="success">
                  Mot de passe modifié avec succès. Vous pouvez maintenant <Link href="/">{"vous reconnecter"}</Link>
                </Alert>
              : null}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >Réinitialiser le mot de passe</Button>
            </Form>
          )}
        </Formik>

        <Typography component="p">
          <Link href="/" title="Revenir à la page de connexion" variant="button">
            Revenir à l'accueil
          </Link>
        </Typography>
      </div>
    </Container>
  );
}

export default ResetPassword;
