import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { BKG_GET_SUCCESS, BKG_GET_FAIL, BKG_GET_START, ROOM_GET_START, ROOM_GET_SUCCESS, ROOM_GET_FAIL, USERS_GET_START, USERS_GET_SUCCESS, USERS_GET_FAIL, Booking } from '../../types';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest, getStartOfToday } from '../../utils/Helpers';


interface BookingsListProps {
  className?: string;
  onDisplayForm: () => void;
}

interface BookingsListFilters {
  previous: boolean;
  active: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const BookingsList = (props: BookingsListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);
  const [filters, setFilters] = useState<BookingsListFilters>({ previous: false, active: true});

  const buildQueryParams = (curFilters: BookingsListFilters): string[] => {
    const activeParam = [`active=${curFilters.active ? 'active' : 'inactive'}`];
    const dateParam = [`${curFilters.previous ? 'to' : 'from'}=${getStartOfToday().toISOString()}`];
    return activeParam.concat(dateParam);
  }

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        dispatch({type: BKG_GET_START});
        const bookingsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/bookings?${buildQueryParams(filters).join('&')}`,
          'GET',
          true,
        );
        dispatch({type: BKG_GET_SUCCESS, payload: bookingsData});
      } catch (err: any) {
        dispatch({type: BKG_GET_FAIL, payload: err.message});
      }
    }

    fetchBookings();
  }, [auth.auth, dispatch, filters]);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        dispatch({type: ROOM_GET_START});
        const roomsData = await apiRequest(
          process.env.REACT_APP_API_URL + '/rooms/',
          'GET',
          true,
        );
        dispatch({type: ROOM_GET_SUCCESS, payload: roomsData});
      } catch (err: any) {
        dispatch({type: ROOM_GET_FAIL, payload: err.message});
      }
    }

    fetchRooms();
  }, [dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch({type: USERS_GET_START});
        const usersData = await apiRequest(
          process.env.REACT_APP_API_URL + '/users/',
          'GET',
          true,
        );
        dispatch({type: USERS_GET_SUCCESS, payload: usersData});
      } catch (err: any) {
        dispatch({type: USERS_GET_FAIL, payload: err.message});
      }
    }

    fetchUsers();
  }, [dispatch]);

  const bookingSorter = (bookingA: Booking, bookingB: Booking): number => {
    return bookingB.timeslotStart.getTime() - bookingA.timeslotStart.getTime();
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Utilisateur</TableCell>
          <TableCell>Salle</TableCell>
          <TableCell>Heure de début</TableCell>
          <TableCell>Heure de fin</TableCell>
          <TableCell>Nombre de places</TableCell>
          <TableCell>Commentaire</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {state.bookings.data.sort(bookingSorter).map((book: Booking, idx: number) => {
        const user = state.users.data.find(user => user._id === book.user);
        const comment = book.events.find(event => event.type === 'CREATION')?.comment;
        return (
          <TableRow key={`book_${idx.toFixed()}`}>
            <TableCell>{user?.firstName} {user?.lastName}</TableCell>
            <TableCell>{state.rooms.data.find(rule => rule._id === book.room)?.name}</TableCell>
            <TableCell>{book.timeslotStart.toLocaleDateString()} {book.timeslotStart.toLocaleTimeString()}</TableCell>
            <TableCell>{book.timeslotEnd.toLocaleDateString()} {book.timeslotEnd.toLocaleTimeString()}</TableCell>
            <TableCell>{book.nbSpaces}</TableCell>
            <TableCell>{comment}</TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        );
      })}
      </TableBody>
    </Table>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Toutes les réservations" action={
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onDisplayForm}
        >Nouvelle réservation</Button>
      } />
      <Divider />

      <CardContent>
        {state.bookings.data.length > 0 ?
          renderTable() : <Alert severity="info">Aucune réservation correspondant aux critères</Alert>
        }
      </CardContent>

      <Divider />
      <CardActions>
        <Button
          type="button"
          onClick={() => setFilters({ previous: !filters.previous, active: filters.active })}
        >{filters.previous ? 'Retourner aux prochaines réservations' : 'Voir les réservations passées'}</Button>
        <Button
          type="button"
          onClick={() => setFilters({ previous : filters.previous, active: !filters.active })}
        >{filters.active ? 'Voir les réservations annulées' : 'Retourner aux réservations actives'}</Button>
      </CardActions>
    </Card>
  );
}

export default BookingsList;
