import React, { useContext, useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { BookingRule, BKGRULE_DELETE_START, BKGRULE_DELETE_SUCCESS, BKGRULE_DELETE_FAIL, BKG_UNITS, BKG_DAYS } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest, formatTimeslot } from '../../utils/Helpers';


interface BookingRulesListProps {
  className?: string;
  onDisplayForm: (rule?: BookingRule) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const BookingRulesList = (props: BookingRulesListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);
  const [successDelete, setSuccessDelete] = useState(false);

  const [ui, setUi] = useState({
    displayDeleteModal: false,
    ruleToDelete: '',
    ruleDeleteSuccess: false,
  });

  const ruleDeletionHandler = async (id: string) => {
    try {
      setSuccessDelete(false);
      dispatch({type: BKGRULE_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + '/bookingRules/' + id,
        'DELETE',
        true
      );
      dispatch({type: BKGRULE_DELETE_SUCCESS, payload: id});
      setSuccessDelete(true);
    } catch (err: any) {
      dispatch({type: BKGRULE_DELETE_FAIL, payload: err.message});
    }
  }

  const handleOpenDialog = (id: string) => {
    setUi({
      displayDeleteModal: true,
      ruleToDelete: id,
      ruleDeleteSuccess: false,
    });
  }

  const handleClosedDialog = (confirmed: boolean) => {
    if (confirmed) {
      ruleDeletionHandler(ui.ruleToDelete);
    }
    setUi({
      displayDeleteModal: false,
      ruleToDelete: '',
      ruleDeleteSuccess: confirmed,
    });
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Salle</TableCell>
          <TableCell>Unité</TableCell>
          <TableCell>Prix unitaire</TableCell>
          <TableCell>Jours</TableCell>
          <TableCell>Créneaux</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.bookingRules.data.map((rule: BookingRule) =>
          <TableRow key={rule._id}>
            <TableCell>{state.rooms.data.find(room => rule.room === room._id)?.name}</TableCell>
            <TableCell>{BKG_UNITS.find(unit => rule.unit === unit.name)?.text}</TableCell>
            <TableCell>{rule.price} €</TableCell>
            <TableCell>{rule.days.map(day => BKG_DAYS.find(unit => day === unit.nbISO)?.text).join(' ')}</TableCell>
            <TableCell>{rule.timeslots.map((slot, index) =>
              <span key={`slot_${rule._id}_${index}`}>{formatTimeslot(slot, ' > ')}<br /></span>
            )}</TableCell>
            <TableCell>
              <ButtonGroup color="primary" variant="outlined" size="small">
                <Button type="button" onClick={() => { onDisplayForm(rule); setSuccessDelete(false); }}>
                  Modifier
                </Button>
                <Button type="button" onClick={() => handleOpenDialog(rule._id)}>
                  Supprimer
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Gestion des règles de réservation des salles" action={
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => { onDisplayForm(); setSuccessDelete(false); }}
          >Ajouter une règle</Button>
          } />
        <Divider />

        <CardContent>
          {state.bookingRules.data.length > 0 ?
            renderTable() : <Alert severity="info">Aucune règle enregistrée</Alert>
          }
          {state.bookingRules.error !== '' ?
            <Alert severity="error">{state.bookingRules.error}</Alert> : null
          }
        </CardContent>

        {successDelete ? (<React.Fragment>
          <Divider />
          <CardActions>
            <Alert severity="success">Règle de réservation supprimée avec succès</Alert>
          </CardActions>
        </React.Fragment>) : null}
      </Card>

      <Dialog
        open={ui.displayDeleteModal}
        onClose={() => handleClosedDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        >
        <DialogTitle id="confirm-dialog-title">Êtes-vous sûr(e) de vouloir supprimer cette règle de réservation ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClosedDialog(false)}>Non</Button>
          <Button onClick={() => handleClosedDialog(true)} color="primary">Confirmer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default BookingRulesList;
