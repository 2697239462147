import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { Select, TextField } from 'formik-material-ui';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { BKG_UNITS, PKG_ADD_START, PKG_ADD_FAIL, PKG_ADD_SUCCESS, PKG_UPDATE_START, PKG_UPDATE_SUCCESS, PKG_UPDATE_FAIL, Package } from '../../types';
import { apiRequest, unitText } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface PackageFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  existing?: Package;
}

interface PackageFormValues {
  _id?: string;
  name: string;
  desc: string;
  rule: string;
  quantity: number;
  price: number;
  validity?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const PackageForm = (props: PackageFormProps) => {
  const { onClose, existing, className, ...rest} = props;

  const classes= useStyles();
  const [state, dispatch] = useContext(storeContext);

  const emptyValues: PackageFormValues = {
    name: '',
    desc: '',
    rule: '',
    quantity: 10,
    price: 1,
    validity: undefined,
  };
  const initialValues: PackageFormValues = existing ?? emptyValues;

  const packageMapper = (values: PackageFormValues): any => {
    const { validity, ...reqValues } = values;
    const valid = (validity !== undefined && validity > 0) ? { validity } : null;
    return Object.assign({}, reqValues, valid);
  }

  const creationHandler = async (values: PackageFormValues) => {
    try {
      dispatch({type: PKG_ADD_START});
      const packData = await apiRequest(
        process.env.REACT_APP_API_URL + '/packages',
        'POST',
        true,
        packageMapper(values)
      );
      dispatch({type: PKG_ADD_SUCCESS, payload: packData});
      onClose(true);
    } catch (err) {
      dispatch({type: PKG_ADD_FAIL, payload: err.message});
    }
  }

  const editionHandler = async (values: PackageFormValues) => {
    try {
      dispatch({type: PKG_UPDATE_START});
      const packData = await apiRequest(
        process.env.REACT_APP_API_URL + '/packages/' + values._id,
        'PUT',
        true,
        values
      );
      dispatch({type: PKG_UPDATE_SUCCESS, payload: packData});
      onClose(true);
    } catch (err) {
      dispatch({type: PKG_UPDATE_FAIL, payload: err.message});
    }
  }

  const quantityField = (ruleId: string) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if (rule) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="quantity">C'est un carnet de</InputLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              component={TextField}
              input="number"
              id="quantity"
              name="quantity"
              label="Nombre"
              required
              helperText={BKG_UNITS.find(unit => unit.name === rule.unit)?.text}
            />
          </Grid>
        </React.Fragment>
      );
    }
  }

  const renderForm = ({ isSubmitting, values, setFieldValue }: FormikProps<PackageFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title={existing ? 'Modifier un carnet' : 'Ajouter un carnet'} />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="name">Je créé le carnet</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field type="hidden" name="_id" />
              <Field
                component={TextField}
                id="name"
                name="name"
                label="Nom du carnet"
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="desc">Description</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                id="desc"
                name="desc"
                label="Description"
                multiline
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="room">Ce carnet permet de réserver avec la règle</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={Select}
                id="rule"
                name="rule"
                native
                required
              >
                <option key="rule_void" value="">Sélectionner une règle de réservation</option>
                {state.bookingRules.data.map(rule => {
                  const roomName = state.rooms.data.filter(room => room._id === rule.room)
                    .map(room => room.name).join('');
                  const text = `${roomName} ${unitText(rule)}`;
                  return <option key={`rule_${rule._id}`} value={rule._id}>{text}</option>;
                })}
              </Field>
            </Grid>

            {values.rule !== '' ? quantityField(values.rule) : null}

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="price">Il coûte</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                type="number"
                id="price"
                name="price"
                label="Prix"
                required
                helperText="€"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="validity">et est valide</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              {values.validity === undefined ? (
                <p>indéfiniment.</p>
              ) : (
                <Field
                  component={TextField}
                  type="number"
                  id="validity"
                  name="validity"
                  label="Pendant"
                  helperText="semaines"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {values.validity === undefined ? (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setFieldValue('validity', 1)}
                >Ajouter durée de validité</Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setFieldValue('validity', undefined)}
                >x</Button>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Enregistrer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.packages.formError !== '' ?
            <Alert severity="error">{state.packages.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Champ nom requis';
        } else if (!values.rule) {
          errors.rule = 'Champ règle requis';
        } else if (!values.quantity) {
          errors.quantity = 'Champ quantité requis';
        } else if (!values.price) {
          errors.price = 'Champ prix requis';
        }
        return errors;
      }}
      onSubmit={(values) => values._id ? editionHandler(values) : creationHandler(values)}
    >
      {renderForm}
    </Formik>
  );
}

export default PackageForm;
