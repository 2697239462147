import { UserAuth, Timeslot, BKG_UNITS, BookingRule } from "../types";

export const apiRequest = async (
  url: string,
  method: string,
  withAuth: boolean,
  bodyParams?: any,
): Promise<any> => {
  const headers = new Headers();
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  if (withAuth) {
    headers.set('Authorization', `Bearer ${getStoredUserAuth()?.token}`);
  }

  const response = await fetch(url, {
    method,
    headers,
    body: bodyParams ? JSON.stringify(bodyParams) : undefined,
    mode: "cors",
  });

  const responseStatus = response.status.toFixed();
  if (responseStatus.startsWith('4') || responseStatus.startsWith('5')) {
    const rawError = await response.text();
    let errorMsg = rawError;
    try {
      const errJson = JSON.parse(rawError);
      errorMsg = errJson.error
    } catch (_) {
    }
    throw Error(errorMsg);
  }

  try {
    return await response.json();
  } catch (err) {
    return '';
  }
}

export const getStoredUserAuth = (): UserAuth | undefined => {
  const auth = localStorage.getItem('authToken');
  if (auth) {
    return JSON.parse(auth);
  }
  return undefined;
}

export const getStartOfToday = (): Date => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export const formatTimeslot = (slot: Timeslot, separator: string): string => {
  const start: string = slot.startHour.toFixed().padStart(2, '0') + ':' + slot.startMinute.toFixed().padStart(2, '0');
  const end: string = slot.endHour.toFixed().padStart(2, '0') + ':' + slot.endMinute.toFixed().padStart(2, '0');
  return start + separator + end;
}

export const unitText = (rule?: BookingRule, withPre: boolean = true): string => {
  return BKG_UNITS.filter(unit => unit.name === rule?.unit)
    .map(unit => withPre ? unit.pre + unit.text : unit.text)
    .join('');
}
