import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { BKG_GET_START, BKG_GET_SUCCESS, BKG_GET_FAIL, Booking, BKG_DELETE_START, BKG_DELETE_SUCCESS, BKG_DELETE_FAIL } from '../../types';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface BookingsListProps {
  className?: string;
  onDisplayForm: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const BookingsList = (props: BookingsListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  const [ui, setUi] = useState({
    displayCancelModal: false,
    bookingToCancel: '',
    bookingCancelledSuccess: false,
  });

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        dispatch({type: BKG_GET_START});
        const bookingsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/bookings`,
          'GET',
          true,
        );
        dispatch({type: BKG_GET_SUCCESS, payload: bookingsData});
      } catch (err: any) {
        dispatch({type: BKG_GET_FAIL, payload: err.message});
      }
    }

    fetchBookings();
  }, [auth.auth, dispatch]);

  const bookingDeletionHandler = async (id: string) => {
    try {
      dispatch({type: BKG_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/bookings/${id}/cancel`,
        'PUT',
        true
      );
      dispatch({type: BKG_DELETE_SUCCESS, payload: id});
    } catch (err: any) {
      dispatch({type: BKG_DELETE_FAIL, payload: err.message});
    }
  }

  const handleOpenDialog = (id: string) => {
    setUi({
      displayCancelModal: true,
      bookingToCancel: id,
      bookingCancelledSuccess: false,
    });
  }

  const handleClosedDialog = (confirmed: boolean) => {
    if (confirmed) {
      bookingDeletionHandler(ui.bookingToCancel);
    }
    setUi({
      displayCancelModal: false,
      bookingToCancel: '',
      bookingCancelledSuccess: confirmed,
    });
  }

  const bookingSorter = (bookingA: Booking, bookingB: Booking): number => {
    return bookingB.timeslotStart.getTime() - bookingA.timeslotStart.getTime();
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Salle</TableCell>
          <TableCell>Heure de début</TableCell>
          <TableCell>Heure de fin</TableCell>
          <TableCell>Nombre de places</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {state.bookings.data.sort(bookingSorter).map((book: Booking, idx: number) =>
        <TableRow key={`book_${idx.toFixed()}`}>
          <TableCell>{state.rooms.data.find(rule => rule._id === book.room)?.name}</TableCell>
          <TableCell>{book.timeslotStart.toLocaleDateString()} {book.timeslotStart.toLocaleTimeString()}</TableCell>
          <TableCell>{book.timeslotEnd.toLocaleDateString()} {book.timeslotEnd.toLocaleTimeString()}</TableCell>
          <TableCell>{book.nbSpaces !== 0 ? book.nbSpaces.toFixed() : 'Toute la salle'}</TableCell>
          <TableCell>
            {book.userCancellable ? (<Button
              type="button"
              color="primary" variant="outlined" size="small"
              onClick={() => handleOpenDialog(book._id)}
            >Annuler</Button>) : null}
          </TableCell>
        </TableRow>
      )}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Mes réservations" action={
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onDisplayForm}
          >Nouvelle réservation</Button>
        } />
        <Divider />

        <CardContent>
          {state.bookings.data.length > 0 ?
            renderTable() : <Alert severity="info">Aucune réservation enregistrée</Alert>
          }
          {state.bookings.error !== '' ?
            <Alert severity="error">{state.bookings.error}</Alert> : null
          }
        </CardContent>

        {ui.bookingCancelledSuccess ? (<React.Fragment>
          <Divider />
          <CardActions>
            <Alert severity="success">Votre réservation a bien été annulée</Alert>
          </CardActions>
        </React.Fragment>) : null}
      </Card>

      <Dialog
        open={ui.displayCancelModal}
        onClose={() => handleClosedDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Êtes-vous sûr(e) de vouloir annuler cette réservation ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClosedDialog(false)}>Non</Button>
          <Button onClick={() => handleClosedDialog(true)} color="primary">Confirmer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default BookingsList;
