import React, { useContext, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Alert from '@material-ui/lab/Alert';

import { BILLS_GET_START, BILLS_GET_SUCCESS, BILLS_GET_FAIL, Billing } from '../../types';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface BillingsListProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const BillingsList = (props: BillingsListProps) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  useEffect(() => {
    const fetchBillings = async () => {
      try {
        dispatch({type: BILLS_GET_START});
        const billsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/billings`,
          'GET',
          true,
        );
        dispatch({type: BILLS_GET_SUCCESS, payload: billsData});
      } catch (err: any) {
        dispatch({type: BILLS_GET_FAIL, payload: err.message});
      }
    }

    fetchBillings();
  }, [auth.auth, dispatch]);

  const billingSorter = (billingA: Billing, billingB: Billing): number => {
    return billingB.creationDate.getTime() - billingA.creationDate.getTime();
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Objet</TableCell>
          <TableCell>Montant</TableCell>
          <TableCell>Statut</TableCell>
          <TableCell>Facture associée</TableCell>
          <TableCell>Commentaire</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.billings.data.sort(billingSorter).map(bill =>
          <TableRow key={bill._id}>
            <TableCell>{bill.creationDate.toLocaleDateString()}</TableCell>
            <TableCell>{bill.subject}</TableCell>
            <TableCell>{bill.amount.toFixed()} €</TableCell>
            <TableCell>
              {bill.gift ?
                <React.Fragment>Offerte le {bill.paymentDate?.toLocaleDateString()}</React.Fragment>
                : bill.paymentDate ?
                <React.Fragment>Payée le {bill.paymentDate?.toLocaleDateString()}</React.Fragment>
                : bill.invoiceDate ?
                <React.Fragment>Facturée le {bill.invoiceDate?.toLocaleDateString()}</React.Fragment>
                : <React.Fragment>Nouvelle</React.Fragment>
              }
            </TableCell>
            <TableCell>{bill.invoice}</TableCell>
            <TableCell>{bill.comment}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Mes commandes" />
      <Divider />

      <CardContent>
        {state.billings.data.length > 0 ?
          renderTable() : <Alert severity="info">Vous n'avez aucune commande en cours ou passée</Alert>
        }
        {state.billings.error !== '' ?
          <Alert severity="error">{state.billings.error}</Alert> : null}
      </CardContent>
    </Card>
  );
}

export default BillingsList;
