import React from 'react';

import { Field } from 'formik';
import { Select } from 'formik-material-ui';


interface TimeSelectProps {
  id: string;
  hourName: string;
  minuteName: string;
  minuteStep: number;
  required: boolean;
}

const TimeSelect = (props: TimeSelectProps) => {
  const { id, hourName, minuteName, minuteStep, required } = props;

  // Hours
  const hours: number[] = Array.from(Array(24).keys());

  // Minutes
  const cleanStep: number = (minuteStep < 1 || minuteStep > 60) ? 1 : Math.round(minuteStep);
  const nbMinutes = Math.floor(60 / cleanStep);
  const minutes: number[] = Array.from(Array(nbMinutes).keys()).map(minute => minute * cleanStep);

  return (
    <React.Fragment>
      <Field
        id={`${id}_hour`}
        name={hourName}
        component={Select}
        native
        required={required}
      >
      {hours.map(hour =>
        <option key={`${id}_${hour.toFixed()}`} value={hour}>{hour.toFixed().padStart(2, '0')}</option>
      )}
      </Field>
      :
      <Field
        id={`${id}_minute`}
        name={minuteName}
        component={Select}
        native
        required={required}
      >
      {minutes.map(minute =>
        <option key={`${id}_${minute.toFixed()}`} value={minute}>{minute.toFixed().padStart(2, '0')}</option>
      )}
      </Field>
    </React.Fragment>
  );
}

export default TimeSelect;
