import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { Billing } from '../../types';

import BillingsList from './BillingsList';
import BillingInvoiceForm from './BillingInvoiceForm';
import BillingPayForm from './BillingPayForm';
import BillingGiftForm from './BillingGiftForm';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const BillingsAdmin = () => {
  const classes = useStyles();
  const [displayForm, setDisplayForm] = useState({invoice: false, pay: false, gift: false});
  const [billingToEdit, setBillingToEdit] = useState<any | undefined>(undefined);
  const [success, setSuccess] = useState({invoice: false, pay: false, gift: false});

  const handleOpenInvoiceForm = (billing: Billing) => {
    setBillingToEdit(billing);
    setDisplayForm({ invoice: true, pay: false, gift: false});
  }
  const handleOpenPayForm = (billing: Billing) => {
    setBillingToEdit(billing);
    setDisplayForm({ invoice: false, pay: true, gift: false});
  }
  const handleOpenGiftForm = (billing: Billing) => {
    setBillingToEdit(billing);
    setDisplayForm({ invoice: false, pay: false, gift: true});
  }

  const handleClosedForm = (success: boolean = false) => {
    setSuccess({
      invoice: displayForm.invoice && success,
      pay: displayForm.pay && success,
      gift: displayForm.gift && success,
    });
    setDisplayForm({ invoice: false, pay: false, gift: false});
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>

        {displayForm.invoice ? (
          <Grid item xs={12}>
            <BillingInvoiceForm
              key={`billinvoice_${billingToEdit?._id}`}
              onClose={handleClosedForm}
              billing={billingToEdit}
            />
          </Grid>
        ) : null}

        {displayForm.pay ? (
          <Grid item xs={12}>
            <BillingPayForm
              key={`billpay_${billingToEdit?._id}`}
              onClose={handleClosedForm}
              billing={billingToEdit}
            />
          </Grid>
        ) : null}

        {displayForm.gift ? (
          <Grid item xs={12}>
            <BillingGiftForm
              key={`billgift_${billingToEdit?._id}`}
              onClose={handleClosedForm}
              billing={billingToEdit}
            />
          </Grid>
        ): null
        }

        {success.invoice ?(
          <Grid item xs={12}>
            <Alert severity="success">Cette commande a été marquée comme facturée</Alert>
          </Grid>
        ) : null}
        {success.pay ?(
          <Grid item xs={12}>
            <Alert severity="success">Cette commande a été marquée comme payée</Alert>
          </Grid>
        ) : null}
        {success.gift ?(
          <Grid item xs={12}>
            <Alert severity="success">Cette commande a été marquée comme offerte</Alert>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <BillingsList
            onDisplayInvoiceForm={handleOpenInvoiceForm}
            onDisplayPayForm={handleOpenPayForm}
            onDisplayGiftForm={handleOpenGiftForm}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default BillingsAdmin;
