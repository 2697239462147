import { Store, Action } from "../types";

import userReducer from "./UserReducer";
import roomsReducer from "./RoomsReducer";
import usersReducer from "./UsersReducer";
import packagesReducer from "./PackagesReducer";
import rulesReducer from "./BookingRulesReducer";
import bookingsReducer from "./BookingsReducer";
import billingsReducer from "./BillingsReducer";
import userPackagesReducer from "./UserPackagesReducer";

const storeReducer = (state: Store, action: Action): Store => {
  return {
    user: userReducer(state.user, action),
    rooms: roomsReducer(state.rooms, action),
    bookingRules: rulesReducer(state.bookingRules, action),
    users: usersReducer(state.users, action),
    packages: packagesReducer(state.packages, action),
    bookings: bookingsReducer(state.bookings, action),
    billings: billingsReducer(state.billings, action),
    userPackages: userPackagesReducer(state.userPackages, action),
  };
}

export default storeReducer;
