import React, { useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FaceIcon from '@material-ui/icons/Face';
import EventIcon from '@material-ui/icons/Event';
import TicketIcon from '@material-ui/icons/LocalActivity';
import ShoppingIcon from '@material-ui/icons/ShoppingBasket';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MoneyIcon from '@material-ui/icons/CreditCard';

import { Link } from 'react-router-dom';
import { authContext } from '../../contexts/AuthContext';


const UserMenu = () => {
  return (
    <React.Fragment>
      <ListItem button component={Link} to='/profile'>
        <ListItemIcon><FaceIcon /></ListItemIcon>
        <ListItemText primary="Mon profil" />
      </ListItem>
      <ListItem button component={Link} to='/bookings'>
        <ListItemIcon><EventIcon /></ListItemIcon>
        <ListItemText primary="Mes réservations" />
      </ListItem>
      <ListItem button component={Link} to='/packages'>
        <ListItemIcon><TicketIcon /></ListItemIcon>
        <ListItemText primary="Mes carnets" />
      </ListItem>
      <ListItem button component={Link} to='/billings'>
        <ListItemIcon><ShoppingIcon /></ListItemIcon>
        <ListItemText primary="Mes commandes" />
      </ListItem>
    </React.Fragment>
  );
}

const AdminMenu = () => {
  return (
    <React.Fragment>
      <Divider />
      <ListSubheader>ADMIN</ListSubheader>

      <ListItem button component={Link} to='/admin/users'>
        <ListItemIcon><PeopleIcon /></ListItemIcon>
        <ListItemText primary="Utilisateurs" />
      </ListItem>
      <ListItem button component={Link} to='/admin/rooms'>
        <ListItemIcon><RoomIcon /></ListItemIcon>
        <ListItemText primary="Salles" />
      </ListItem>
      <ListItem button component={Link} to='/admin/bookingRules'>
        <ListItemIcon><EventNoteIcon /></ListItemIcon>
        <ListItemText primary="Règles de réservation" />
      </ListItem>
      <ListItem button component={Link} to='/admin/packages'>
        <ListItemIcon><TicketIcon /></ListItemIcon>
        <ListItemText primary="Carnets de crédits" />
      </ListItem>
      <ListItem button component={Link} to='/admin/bookings'>
        <ListItemIcon><EventIcon /></ListItemIcon>
        <ListItemText primary="Réservations" />
      </ListItem>
      <ListItem button component={Link} to='/admin/billings'>
        <ListItemIcon><MoneyIcon /></ListItemIcon>
        <ListItemText primary="Facturation" />
      </ListItem>
    </React.Fragment>
  );
}

const Menu = () => {
  const auth = useContext(authContext);
  const isAdmin = auth.auth && auth.auth.user.roles.indexOf('admin') !== -1;

  return (
    <nav>
      <UserMenu />
      { isAdmin ? <AdminMenu /> : null }
    </nav>
  );
}

export default Menu;
