import React, { useContext, useEffect, useState } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { Package, UserPackage, User, USERPKG_UPDATE_START, USERPKG_UPDATE_SUCCESS, USERPKG_UPDATE_FAIL } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface UserPackageValidityFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  user: User;
  pack: UserPackage;
}

interface UserPackageValidityFormValues {
  _id: string;
  start: Date;
  validityEnd: Date;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserPackageValidityForm = (props: UserPackageValidityFormProps) => {
  const { user, pack, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const [computedValidityEnd, setComputedValidityEnd] = useState<Date | undefined>(undefined);

  const initialValues: UserPackageValidityFormValues = {
    _id: pack._id,
    start: pack.start,
    validityEnd: pack.validityEnd ?? new Date(),
  };

  useEffect(() => {
    computeValidityEnd(pack.package, pack.start);
  }, [pack]);

  const validityEditHandler = async (values: UserPackageValidityFormValues) => {
    try {
      dispatch({type: USERPKG_UPDATE_START});
      const userPackageData = await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${user._id}/packages/${pack._id}/validity`,
        'PUT',
        true,
        values
      );
      dispatch({type: USERPKG_UPDATE_SUCCESS, payload: userPackageData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERPKG_UPDATE_FAIL, payload: err});
    }
  }

  const computeValidityEnd = (pack: Package, date: Date): Date | undefined => {
    if (pack.validity && pack.validity > 0) {
      const computedDate = moment(date).add(pack.validity, 'week').toDate();
      setComputedValidityEnd(computedDate);
      return computedDate;
    } else {
      return undefined;
    }
  }

  const renderForm = ({ values, isSubmitting }: FormikProps<UserPackageValidityFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Modifier les dates de validité" />
        <Divider />

        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="start">Validité</InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field type="hidden" name="_id" />
              <Field
                component={DatePicker}
                id="start"
                name="start"
                label="Début de validité"
                required
                format="DD MMM yyyy"
                disableToolbar
                helperText="Date de début de validité incluse"
                onAccept={(date: Moment) => computeValidityEnd(pack.package, date.toDate())}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={DatePicker}
                id="validityEnd"
                name="validityEnd"
                label="Fin de validité"
                required
                format="DD MMM yyyy"
                disableToolbar
                disablePast
                minDate={moment(values.start).add(1, 'day').toDate()}
                minDateMessage="La date de fin de validité doit être postérieure à la date de début"
                helperText="Date de fin de validité exclue"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              Avec cette date de début, la date de fin théorique est le {computedValidityEnd?.toLocaleDateString()}
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Confirmer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.userPackages.formError !== '' ?
            <Alert severity="error">{state.userPackages.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (!values.start) {
            errors.start = 'Date de début de validité requise';
          } else if (!values.validityEnd) {
            errors.validityEnd = 'Date de fin de validité requise';
          } else if (moment(values.start).isSameOrAfter(moment(values.validityEnd), 'day')) {
            errors.validityEnd = 'La date de fin de validité doit être postérieure à la date de début';
          }
          return errors;
        }}
        onSubmit={(values) => validityEditHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default UserPackageValidityForm;
