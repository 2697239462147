import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { USER_GET_START, USER_GET_SUCCESS, USER_GET_FAIL } from '../../types';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';

import ProfileForm from './ProfileForm';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const Profile = () => {
  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        dispatch({type: USER_GET_START});
        const userData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/`,
          'GET',
          true,
        );
        dispatch({type: USER_GET_SUCCESS, payload: userData});
      } catch (err) {
        dispatch({type: USER_GET_FAIL, payload: err.message});
      }
    }

    fetchUser();
  }, [auth.auth, dispatch]);

  const handleClosedForm = (success?: boolean) => {
    if (success) {
      setSuccess(true);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {state.user.data ? <ProfileForm
            existing={state.user.data}
            onClose={handleClosedForm}
          /> : null}
        </Grid>

        {success ? (
          <Grid item xs={12}>
            <Alert severity="success">Modifications enregistrées avec succès</Alert>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default Profile;
