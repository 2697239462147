import React, { useContext, useEffect } from 'react';

import moment from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { USERPKG_GET_START, USERPKG_GET_SUCCESS, USERPKG_GET_FAIL, UserPackage } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';


interface PackagesListProps {
  className?: string;
  onDisplayForm: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const PackagesList = (props: PackagesListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  useEffect(() => {
    const fetchUserPackages = async () => {
      try {
        dispatch({type: USERPKG_GET_START});
        const packagesData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/packages`,
          'GET',
          true,
        );
        dispatch({type: USERPKG_GET_SUCCESS, payload: packagesData});
      } catch (err: any) {
        dispatch({type: USERPKG_GET_FAIL, payload: err.message});
      }
    }

    fetchUserPackages();
  }, [auth.auth, dispatch]);

  const packageSorter = (userPackA: UserPackage, userPackB: UserPackage): number => {
    return userPackB.start.getTime() - userPackA.start.getTime();
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Quantité restante</TableCell>
          <TableCell>Commence le</TableCell>
          <TableCell>Validité</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.userPackages.data.sort(packageSorter).map((userPack: UserPackage, idx: number) => {
          const validity = userPack.validityEnd ?
            `jusqu'au ${moment(userPack.validityEnd).subtract(1, 'day').format('DD/MM/YYYY')} inclus`
            : 'sans limitation de durée';
          return (
            <TableRow key={`pack_${idx.toFixed()}`}>
              <TableCell>{userPack.package.name}</TableCell>
              <TableCell>{userPack.remaining.toFixed()}</TableCell>
              <TableCell>{userPack.start.toLocaleDateString()}</TableCell>
              <TableCell>{validity}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Mes carnets" action={
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onDisplayForm}
        >Commander un carnet</Button>
      } />
      <Divider />

      <CardContent>
        {state.userPackages.data.length > 0 ?
          renderTable() : <Alert severity="info">Vous n'avez aucun carnet</Alert>}
        {state.userPackages.loadingError !== '' ?
          <Alert severity="error">{state.userPackages.loadingError}</Alert> : null
        }
      </CardContent>
    </Card>
  );
}

export default PackagesList;
