import React, { useContext, useState, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { Room, ROOM_GET_START, ROOM_GET_SUCCESS, ROOM_GET_FAIL, ROOM_DELETE_START, ROOM_DELETE_SUCCESS, ROOM_DELETE_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface RoomsListProps {
  className?: string;
  onDisplayForm: (room?: Room) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const RoomsList = (props: RoomsListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);
  const [successDelete, setSuccessDelete] = useState(false);

  const [ui, setUi] = useState({
    displayDeleteModal: false,
    roomToDelete: '',
    roomDeleteSuccess: false,
  });

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        dispatch({type: ROOM_GET_START});
        const roomsData = await apiRequest(
          process.env.REACT_APP_API_URL + '/rooms/',
          'GET',
          true,
        );
        dispatch({type: ROOM_GET_SUCCESS, payload: roomsData});
      } catch (err: any) {
        dispatch({type: ROOM_GET_FAIL, payload: err.message});
      }
    }

    fetchRooms();
  }, [dispatch]);

  const roomDeletionHandler = async (id: string) => {
    try {
      setSuccessDelete(false);
      dispatch({type: ROOM_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + '/rooms/' + id,
        'DELETE',
        true
      );
      dispatch({type: ROOM_DELETE_SUCCESS, payload: id});
      setSuccessDelete(true);
    } catch (err: any) {
      dispatch({type: ROOM_DELETE_FAIL, payload: err.message});
    }
  }

  const handleOpenDialog = (id: string) => {
    setUi({
      displayDeleteModal: true,
      roomToDelete: id,
      roomDeleteSuccess: false,
    });
  }

  const handleClosedDialog = (confirmed: boolean) => {
    if (confirmed) {
      roomDeletionHandler(ui.roomToDelete);
    }
    setUi({
      displayDeleteModal: false,
      roomToDelete: '',
      roomDeleteSuccess: confirmed,
    });
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.rooms.data.map((room: Room) =>
          <TableRow key={room._id}>
            <TableCell>{room.name}</TableCell>
            <TableCell>{room.desc}</TableCell>
            <TableCell>
              <ButtonGroup color="primary" variant="outlined" size="small">
                <Button type="button" onClick={() => { onDisplayForm(room); setSuccessDelete(false); }}>
                  Modifier
                </Button>
                <Button type="button" onClick={() => handleOpenDialog(room._id)}>
                  Supprimer
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Gestion des salles" action={
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => { onDisplayForm(); setSuccessDelete(false); }}
          >Ajouter une salle</Button>
        } />
        <Divider />

        <CardContent>
          {state.rooms.data.length > 0 ?
            renderTable() : <Alert severity="info">Aucune salle enregistrée</Alert>
          }
          {state.rooms.error !== '' ?
            <Alert severity="error">{state.rooms.error}</Alert> : null
          }
        </CardContent>

        {successDelete ? (<React.Fragment>
          <Divider />
          <CardActions>
              <Alert severity="success">Salle supprimée avec succès</Alert>
          </CardActions>
        </React.Fragment>) : null}
      </Card>

      <Dialog
        open={ui.displayDeleteModal}
        onClose={() => handleClosedDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        >
        <DialogTitle id="confirm-dialog-title">Êtes-vous sûr(e) de vouloir supprimer cette salle ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClosedDialog(false)}>Non</Button>
          <Button onClick={() => handleClosedDialog(true)} color="primary">Confirmer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RoomsList;
