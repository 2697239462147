import { Action, BILLS_GET_START, BillingsState, BILLS_ADD_START, BILLS_DELETE_START, BILLS_GET_SUCCESS, BILLS_GET_FAIL, BILLS_ADD_SUCCESS, BILLS_ADD_FAIL, BILLS_UPDATE_START, BILLS_UPDATE_SUCCESS, BILLS_UPDATE_FAIL, BILLS_DELETE_SUCCESS, BILLS_DELETE_FAIL, billingMapper } from "../types";

const billingsReducer = (state: BillingsState, action: Action): BillingsState => {
  switch (action.type) {
    case BILLS_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BILLS_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(billingMapper(action.payload)),
        formError: '',
      };
    case BILLS_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BILLS_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BILLS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.map(billingMapper),
        error: '',
      };
    case BILLS_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case BILLS_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BILLS_UPDATE_SUCCESS:
      const updatedBilling = billingMapper(action.payload);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(bill => {
          return (bill._id === updatedBilling._id) ? updatedBilling : bill;
        }),
        formError: '',
      };
    case BILLS_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BILLS_DELETE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BILLS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(type => type._id !== (action.payload as string)),
      };
    case BILLS_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    default:
      return state;
  }
}

export default billingsReducer;
