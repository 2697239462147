import { Action, ROOM_GET_START, Room, RoomsState, ROOM_ADD_START, ROOM_DELETE_START, ROOM_GET_SUCCESS, ROOM_GET_FAIL, ROOM_ADD_SUCCESS, ROOM_ADD_FAIL, ROOM_UPDATE_START, ROOM_UPDATE_SUCCESS, ROOM_UPDATE_FAIL, ROOM_DELETE_SUCCESS, ROOM_DELETE_FAIL } from "../types";

const roomsReducer = (state: RoomsState, action: Action): RoomsState => {
  switch (action.type) {
    case ROOM_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case ROOM_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(action.payload as Room),
        formError: '',
      };
    case ROOM_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case ROOM_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case ROOM_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as Array<Room>),
        error: '',
      };
    case ROOM_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case ROOM_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case ROOM_UPDATE_SUCCESS:
      const updatedRoom = (action.payload as Room);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(room => {
          return (room._id === updatedRoom._id) ? updatedRoom : room;
        }),
        formError: '',
      };
    case ROOM_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case ROOM_DELETE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case ROOM_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(type => type._id !== (action.payload as string)),
      };
    case ROOM_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    default:
      return state;
  }
}

export default roomsReducer;
