import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { Room } from '../../types';

import RoomForm from './RoomForm';
import RoomsList from './RoomsList';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const RoomsAdmin = () => {
  const classes = useStyles();
  const [displayForm, setDisplayForm] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState<Room | undefined>(undefined);
  const [successAdd, setSuccessAdd] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);

  const handleOpenForm = (room?: Room) => {
    setDisplayForm(true);
    setRoomToEdit(room);
    setSuccessAdd(false);
    setSuccessEdit(false);
  }

  const handleClosedForm = (success?: boolean) => {
    setDisplayForm(false);
    if (success && !roomToEdit) {
      setSuccessAdd(true);
    } else if (success && roomToEdit) {
      setSuccessEdit(true);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {displayForm ? (
          <Grid item xs={12}>
            <RoomForm
              key={(roomToEdit !== undefined) ? `user${roomToEdit._id}` : `usernew`}
              onClose={handleClosedForm}
              existing={roomToEdit}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <RoomsList onDisplayForm={handleOpenForm} />
        </Grid>

        {successAdd ? (
          <Grid item xs={12}>
            <Alert severity="success">Salle ajoutée avec succès</Alert>
          </Grid>
        ) : null}
        {successEdit ? (
          <Grid item xs={12}>
            <Alert severity="success">Salle modifiée avec succès</Alert>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default RoomsAdmin;
