import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { User } from '../../types';

import UserForm from './UserForm';
import UsersList from './UsersList';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const UsersAdmin = () => {
  const classes = useStyles();
  const [ui, setUi] = useState({
    displayUserForm: false,
    userAddSuccess: false,
    userEditSucess: false,
  });
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);

  const handleOpenUserForm = (user?: User) => {
    setUi({
      displayUserForm: true,
      userAddSuccess: false,
      userEditSucess: false,
    });
    setUserToEdit(user);
  }

  const handleClosedForm = (success: boolean = false) => {
    setUi({
      displayUserForm: false,
      userAddSuccess: ui.displayUserForm && !userToEdit && success,
      userEditSucess: ui.displayUserForm && !!userToEdit && success,
    });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>

        {ui.displayUserForm ? (
          <Grid item xs={12}>
            <UserForm
              key={(userToEdit !== undefined) ? `user${userToEdit._id}` : `usernew`}
              onClose={handleClosedForm}
              existing={userToEdit}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <UsersList onDisplayUserForm={handleOpenUserForm} />
          </Grid>
        )}

        {ui.userAddSuccess ? (
          <Grid item xs={12}>
            <Alert severity="success">Utilisateur ajouté avec succès</Alert>
          </Grid>
        ) : null}
        {ui.userEditSucess ? (
          <Grid item xs={12}>
            <Alert severity="success">Utilisateur modifié avec succès</Alert>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default UsersAdmin;
