import { useState } from 'react';

import { UserAuth } from '../types';

const useAuthHandler = (initialState?: UserAuth) => {
  const [auth, setAuth] = useState(initialState);

  const setAuthStatus = (userAuth: UserAuth) => {
    localStorage.setItem('authToken', JSON.stringify(userAuth));
    setAuth(userAuth);
  }

  const setUnauthStatus = () => {
    localStorage.clear();
    setAuth(undefined);
  }

  return {
    auth,
    setAuthStatus,
    setUnauthStatus,
  };
}

export default useAuthHandler;
