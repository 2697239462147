import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { BILLS_UPDATE_START, BILLS_UPDATE_SUCCESS, BILLS_UPDATE_FAIL, Billing } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface BillingPayFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  billing: Billing;
}

interface BillingFormValues {
  _id: string;
  paymentDate: Date;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const BillingPayForm = (props: BillingPayFormProps) => {
  const { billing, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const initialValues: BillingFormValues = {
    _id: billing._id,
    paymentDate: new Date(),
  };

  const userNameFrom = (billing: Billing) => {
    const user = state.users.data.find(user => user._id === billing.user);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  const payHandler = async (values: BillingFormValues) => {
    try {
      dispatch({type: BILLS_UPDATE_START});
      const billData = await apiRequest(
        process.env.REACT_APP_API_URL + `/billings/${billing._id}/pay`,
        'PUT',
        true,
        values
      );
      dispatch({type: BILLS_UPDATE_SUCCESS, payload: billData});
      onClose(true);
    } catch (err: any) {
      dispatch({type: BILLS_UPDATE_FAIL, payload: err.message});
    }
  }

  const renderForm = ({ isSubmitting}: FormikProps<BillingFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Marquer la facture comme payée" />
        <Divider />

        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <p>
                {billing.subject} pour {userNameFrom(billing)} d'un montant de {billing.amount.toFixed()} €
              </p>
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="paymentDate">a été réglée le</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field type="hidden" name="_id" />
              <Field
                component={DatePicker}
                id="paymentDate"
                name="paymentDate"
                label="Date"
                required
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Confirmer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.billings.formError !== '' ?
            <Alert severity="error">{state.billings.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => payHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default BillingPayForm;
