import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { User, USER_UPDATE_START, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface ProfileFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  existing: User;
}

interface ProfileFormValues {
  email: string;
  firstName: string;
  lastName: string;
  pwdTmp: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ProfileForm = (props: ProfileFormProps) => {
  const { onClose, existing, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const initialValues: ProfileFormValues = {
    email: existing.email,
    firstName: existing.firstName,
    lastName: existing.lastName,
    pwdTmp: '',
  };

  const editHandler = async (values: ProfileFormValues) => {
    try {
      dispatch({type: USER_UPDATE_START});
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${existing._id}`,
        'PUT',
        true,
        values
      );
      dispatch({type: USER_UPDATE_SUCCESS, payload: userData});
      onClose(true);
    } catch (err) {
      dispatch({type: USER_UPDATE_FAIL, payload: err.message});
    }
  }

  const renderForm = ({ isSubmitting, setValues, initialValues }: FormikProps<ProfileFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Modifier mon profil" />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                id="email"
                type="email"
                name="email"
                label="Adresse e-mail"
                required
                fullWidth
                autoComplete="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                id="firstName"
                name="firstName"
                label="Prénom"
                required
                fullWidth
                autoComplete="fname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                id="lastName"
                name="lastName"
                label="Nom"
                required
                fullWidth
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                type="password"
                id="pwdTmp"
                name="pwdTmp"
                label="Nouveau mot de passe"
                helperText="Laisser vide pour ne pas le modifier"
                required={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Enregistrer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => setValues(initialValues)}
          >Annuler</Button>

          {state.user.formError !== '' ?
            <Alert severity="error">{state.user.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {};
        if (!values.email) {
          errors.email = 'Champ requis';
        } else if (!values.firstName) {
          errors.firstName = 'Champ requis';
        } else if (!values.lastName) {
          errors.lastName = 'Champ requis';
        }
        return errors;
      }}
      onSubmit={(values) => editHandler(values)}
    >
      {renderForm}
    </Formik>
  );
}

export default ProfileForm;
