import React, { useContext, useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { Package, PKG_DELETE_START, PKG_DELETE_SUCCESS, PKG_DELETE_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest, unitText } from '../../utils/Helpers';


interface PackagesListProps {
  className?: string;
  onDisplayForm: (pack?: Package) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const PackagesList = (props: PackagesListProps) => {
  const { onDisplayForm, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);
  const [successDelete, setSuccessDelete] = useState(false);

  const [ui, setUi] = useState({
    displayDeleteModal: false,
    packageToDelete: '',
    packageDeleteSuccess: false,
  });

  const packageDeletionHandler = async (id: string) => {
    try {
      setSuccessDelete(false);
      dispatch({type: PKG_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + '/packages/' + id,
        'DELETE',
        true
      );
      dispatch({type: PKG_DELETE_SUCCESS, payload: id});
      setSuccessDelete(true);
    } catch (err: any) {
      dispatch({type: PKG_DELETE_FAIL, payload: err.message});
    }
  }

  const handleOpenDialog = (id: string) => {
    setUi({
      displayDeleteModal: true,
      packageToDelete: id,
      packageDeleteSuccess: false,
    });
  }

  const handleClosedDialog = (confirmed: boolean) => {
    if (confirmed) {
      packageDeletionHandler(ui.packageToDelete);
    }
    setUi({
      displayDeleteModal: false,
      packageToDelete: '',
      packageDeleteSuccess: confirmed,
    });
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Quantité</TableCell>
          <TableCell>Salle</TableCell>
          <TableCell>Unité</TableCell>
          <TableCell>Prix</TableCell>
          <TableCell>Validité</TableCell>
          <TableCell>Actions</TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        {state.packages.data.map((pack: Package) => {
          const rule = state.bookingRules.data.find(rule => pack.rule === rule._id);
          return (
            <TableRow key={pack._id}>
              <TableCell>{pack.name}</TableCell>
              <TableCell>{pack.quantity}</TableCell>
              <TableCell>{state.rooms.data.find(room => room._id === rule?.room)?.name}</TableCell>
              <TableCell>{unitText(rule)}</TableCell>
              <TableCell>{pack.price} €</TableCell>
              <TableCell>{pack.validity ? `${pack.validity} semaines` : '-'}</TableCell>
              <TableCell>
                <ButtonGroup color="primary" variant="outlined" size="small">
                  <Button type="button" onClick={() => { onDisplayForm(pack); setSuccessDelete(false); }}>
                    Modifier
                  </Button>
                  <Button type="button" onClick={() => handleOpenDialog(pack._id)}>
                    Supprimer
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Gestion des carnets de crédits" action={
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => { onDisplayForm(); setSuccessDelete(false); }}
          >Ajouter un carnet</Button>
        } />
        <Divider />

        <CardContent>
          {state.packages.data.length > 0 ?
            renderTable() : <Alert severity="info">Aucun carnet enregistré</Alert>
          }
          {state.packages.error !== '' ?
            <Alert severity="error">{state.packages.error}</Alert> : null
          }
        </CardContent>

        {successDelete ? (<React.Fragment>
          <Divider />
          <CardActions>
            <Alert severity="success">Carnet supprimé avec succès</Alert>
          </CardActions>
        </React.Fragment>) : null}
      </Card>

      <Dialog
        open={ui.displayDeleteModal}
        onClose={() => handleClosedDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        >
        <DialogTitle id="confirm-dialog-title">Êtes-vous sûr(e) de vouloir supprimer ce carnet ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClosedDialog(false)}>Non</Button>
          <Button onClick={() => handleClosedDialog(true)} color="primary">Confirmer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default PackagesList;
