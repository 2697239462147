import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { Room, BookingSlot, bookingSlotMapper } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface BookingsViewProps {
  date: Date;
  rooms: Array<Room>;
  fullView?: boolean;
}

interface SimpleSlotView {
  text: string;
  start: number;
  end: number;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    tableLayout: 'fixed',
    textAlign: 'left',
    width: '100%',
  },
  roomHeader: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  timeHeader: {
    borderLeft: '1px solid black',
  },
  occupied: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const BookingsView = (props: BookingsViewProps) => {
  const { date, rooms, fullView } = props;

  const classes = useStyles();
  const [state] = useContext(storeContext);
  const [slots, setSlots] = useState<BookingSlot[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBookings = async () => {
      const nextDate: Date = moment(date).add(1, 'day').toDate();
      const showFilter: string = (fullView !== undefined && fullView) ? 'all' : 'min';

      try {
        const slotsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/bookingSlots?from=${date.toISOString()}&to=${nextDate.toISOString()}&show=${showFilter}`,
          'GET',
          true,
        );
        setSlots(slotsData.map(bookingSlotMapper));
      } catch (err) {
        setError(true);
      }
    }

    fetchBookings();
  }, [date, fullView]);

  const reduceRoomSlots = (room: Room, roomSlots: SimpleSlotView[]): SimpleSlotView[][] => {
    // Compute max capacity
    const maxCapacity = state.bookingRules.data
      .filter(rule => rule.room === room._id)
      .reduce((max, rule) => (rule.shareable && rule.capacity > max) ? rule.capacity : max, 1);

    // Create bookingSlots map
    const initialBookMap = Array(maxCapacity).fill(0).map(_ => []);

    const slotMap: SimpleSlotView[][] = roomSlots.reduce((acc: SimpleSlotView[][], slot: SimpleSlotView) => {
      let firstAvailableSpaceIdx = -1;
      for (let space = 0; space < acc.length && firstAvailableSpaceIdx === -1; space++) {
        const doesOverlap = acc[space].some(placedSlot => slot.end > placedSlot.start && slot.start < placedSlot.end);
        if (!doesOverlap) {
          firstAvailableSpaceIdx = space;
        }
      }

      if (firstAvailableSpaceIdx !== -1) {
        // Normal case
        return acc.slice(0, firstAvailableSpaceIdx)
        .concat([acc[firstAvailableSpaceIdx].concat(slot).sort((a, b) => a.start - b.start)])
        .concat(acc.slice(firstAvailableSpaceIdx + 1));
      } else {
        // Too many reservations regarding max capacity: adding one more space
        return acc.concat([[slot]]);
      }
    }, initialBookMap);

    return slotMap;
  }

  const renderRoomSlot = (slots: SimpleSlotView[], roomIdx: string, placeIdx: string) => {
    const key: string = `room_${roomIdx}_slot_${placeIdx}`;
    const nbBookings = slots.length;

    if (nbBookings === 0) {
      return <tr key={key}><td key={`${key}_0`} colSpan={48}>&nbsp;</td></tr>;
    }

    let slotIdx: number = 0;
    let lastOffset: number = 0;
    const tds: Array<{key: string, className?: string, colSpan: number, text?: string}> = [];

    slots.forEach(slot => {
      if (slot.start > lastOffset) {
        tds.push({
          key: `${key}_${slotIdx.toFixed()}`,
          colSpan: slot.start - lastOffset,
        });
        slotIdx++;
      }

      tds.push({
        key: `${key}_${slotIdx.toFixed()}`,
        className: classes.occupied,
        colSpan: slot.end - slot.start,
        text: slot.text,
      });

      lastOffset = slot.end;
      slotIdx++;
    });

    if (lastOffset < 48) {
      tds.push({
        key: `${key}_${slotIdx.toFixed()}`,
        colSpan: 48 - lastOffset,
      });
    }

    return (
      <tr key={key}>
        {tds.map(td => (
          <td
            key={td.key}
            className={td.className}
            colSpan={td.colSpan}
          >{td.text}</td>
        ))}
      </tr>
    );
  };

  const renderTimeHeader = (roomIdx: string) => (
    <tr key={`room_${roomIdx}_time`}>
      <th className={classes.timeHeader} colSpan={6}>00:00</th>
      <th className={classes.timeHeader} colSpan={6}>03:00</th>
      <th className={classes.timeHeader} colSpan={6}>06:00</th>
      <th className={classes.timeHeader} colSpan={6}>09:00</th>
      <th className={classes.timeHeader} colSpan={6}>12:00</th>
      <th className={classes.timeHeader} colSpan={6}>15:00</th>
      <th className={classes.timeHeader} colSpan={6}>18:00</th>
      <th className={classes.timeHeader} colSpan={6}>21:00</th>
    </tr>
  );

  const toSimpleSlots = (slot: BookingSlot): SimpleSlotView[] => {
    const slotStart = slot.timeslotStart.getHours() * 60 + slot.timeslotStart.getMinutes();
    const slotEnd = slot.timeslotEnd.getHours() * 60 + slot.timeslotEnd.getMinutes();
    const slotStartOffset = slotStart / 30;
    const slotEndOffset = slotEnd === 0 ? 48 : slotEnd / 30;

    if (slot.nbSpaces > 1) {
      return Array(slot.nbSpaces).fill(0).map((_, idx) => {
        let text: string = 'occupé';
        if (slot.userName) {
          if (idx === 0) {
            text = slot.userName;
          } else {
            text = `${slot.userName} (invité n°${idx.toFixed()})`;
          }
        }

        return {
          text,
          start: slotStartOffset,
          end: slotEndOffset,
        };
      });
    } else {
      return [{
        text: slot.userName ? slot.userName : 'occupé',
        start: slotStartOffset,
        end: slotEndOffset,
      }];
    }
  }

  const renderRoom = (room: Room, idx: number) => {
    const roomIdx: string = idx.toFixed();
    const roomSimpleSlots: SimpleSlotView[] = slots
      .filter(slot => slot.room === room._id)
      .flatMap(toSimpleSlots)
      .sort((a, b) => a.start - b.start);

    const slotViewsByPlace: SimpleSlotView[][] = reduceRoomSlots(room, roomSimpleSlots);

    if (slotViewsByPlace.length > 0) {
      return (
        <React.Fragment key={`room_${roomIdx}`}>
          <thead>
            <tr key={`room_${roomIdx}_name`}>
              <th colSpan={48} className={classes.roomHeader}>{room.name}</th>
            </tr>
            {renderTimeHeader(roomIdx)}
          </thead>
          <tbody>
          {slotViewsByPlace.map((placeSlots, placeIdx) => renderRoomSlot(placeSlots, roomIdx, placeIdx.toFixed()))}
          </tbody>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={`room_${roomIdx}`}>
          <thead>
            <tr key={`room_${roomIdx}_name`}>
              <th colSpan={48} className={classes.roomHeader}>{room.name}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={48}>Aucune réservation pour ce jour</td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      {!error ? (
        <table className={classes.table}>
        {rooms.map(renderRoom)}
        </table>
      ) : null}

      {error ? <Alert severity="error">Erreur de téléchargement des réservations</Alert> : null}
    </React.Fragment>
  );
}

export default BookingsView;
