import { Action, USERS_GET_START, User, UsersState, USERS_ADD_START, USERS_DELETE_START, USERS_ADD_SUCCESS, USERS_ADD_FAIL, USERS_GET_SUCCESS, USERS_GET_FAIL, USERS_DELETE_SUCCESS, USERS_DELETE_FAIL, USERS_UPDATE_START, USERS_UPDATE_SUCCESS, USERS_UPDATE_FAIL } from "../types";

const usersReducer = (state: UsersState, action: Action): UsersState => {
  switch (action.type) {
    case USERS_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case USERS_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(action.payload as User),
      };
    case USERS_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case USERS_GET_START:
      return {
        ...state,
        isLoading: true,
        loadingError: '',
      };
    case USERS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as Array<User>),
        loadingError: '',
      };
    case USERS_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        loadingError: (action.payload as string),
      };
    case USERS_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case USERS_UPDATE_SUCCESS:
      const updatedUser = (action.payload as User);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(user => {
          return (user._id === updatedUser._id) ? updatedUser : user;
        }),
        formError: '',
      };
    case USERS_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case USERS_DELETE_START:
      return {
        ...state,
        isLoading: true,
        loadingError: '',
      };
    case USERS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(user => user._id !== (action.payload as string)),
      };
    case USERS_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        loadingError: (action.payload as string),
      };
    default:
      return state;
  }
}

export default usersReducer;
