import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { Package } from '../../types';

import PackageForm from './PackageForm';
import PackagesList from './PackagesList';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const PackagesAdmin = () => {
  const classes = useStyles();
  const [displayForm, setDisplayForm] = useState(false);
  const [packageToEdit, setPackageToEdit] = useState<Package | undefined>(undefined);
  const [successAdd, setSuccessAdd] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);

  const handleOpenForm = (pack?: Package) => {
    setDisplayForm(true);
    setPackageToEdit(pack);
    setSuccessAdd(false);
    setSuccessEdit(false);
  }

  const handleClosedForm = (success?: boolean) => {
    setDisplayForm(false);
    if (success && !packageToEdit) {
      setSuccessAdd(true);
    } else if (success && packageToEdit) {
      setSuccessEdit(true);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {displayForm ? (
          <Grid item xs={12}>
            <PackageForm
              key={(packageToEdit !== undefined) ? `pack${packageToEdit._id}` : `packnew`}
              onClose={handleClosedForm}
              existing={packageToEdit}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <PackagesList onDisplayForm={handleOpenForm} />
        </Grid>

        {successAdd ? (
          <Grid item xs={12}>
            <Alert severity="success">Carnet ajouté avec succès</Alert>
          </Grid>
        ) : null}
        {successEdit ? (
          <Grid item xs={12}>
            <Alert severity="success">Carnet modifié avec succès</Alert>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default PackagesAdmin;
