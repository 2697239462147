import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { ROOM_ADD_START, ROOM_ADD_FAIL, ROOM_ADD_SUCCESS, ROOM_UPDATE_START, ROOM_UPDATE_SUCCESS, ROOM_UPDATE_FAIL, Room } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface RoomFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  existing?: Room;
}

interface RoomFormValues {
  _id?: string;
  name: string;
  desc: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const RoomForm = (props: RoomFormProps) => {
  const { onClose, existing, className, ...rest} = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const emptyValues: RoomFormValues = {
    name: '',
    desc: '',
  };
  const initialValues: RoomFormValues = existing || emptyValues;

  const creationHandler = async (values: RoomFormValues) => {
    try {
      dispatch({type: ROOM_ADD_START});
      const roomData = await apiRequest(
        process.env.REACT_APP_API_URL + '/rooms',
        'POST',
        true,
        values
      );
      dispatch({type: ROOM_ADD_SUCCESS, payload: roomData});
      onClose(true);
    } catch (err) {
      dispatch({type: ROOM_ADD_FAIL, payload: err.message});
    }
  }

  const editionHandler = async (values: RoomFormValues) => {
    try {
      dispatch({type: ROOM_UPDATE_START});
      const roomData = await apiRequest(
        process.env.REACT_APP_API_URL + '/rooms/' + values._id,
        'PUT',
        true,
        values
      );
      dispatch({type: ROOM_UPDATE_SUCCESS, payload: roomData});
      onClose(true);
    } catch (err) {
      dispatch({type: ROOM_UPDATE_FAIL, payload: err.message});
    }
  }

  const renderForm = ({ isSubmitting}: FormikProps<RoomFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title={existing ? 'Modifier une salle' : 'Ajouter une salle'} />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field type="hidden" name="_id" />
              <Field
                component={TextField}
                id="name"
                name="name"
                label="Nom de la salle"
                required
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                id="desc"
                name="desc"
                label="Description"
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Enregistrer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.rooms.formError !== '' ?
            <Alert severity="error">{state.rooms.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Champ nom requis';
        }
        return errors;
      }}
      onSubmit={(values) => values._id ? editionHandler(values) : creationHandler(values)}
    >
      {renderForm}
    </Formik>
  );
}

export default RoomForm;
