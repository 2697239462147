import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';

import { BILLS_GET_START, BILLS_GET_SUCCESS, BILLS_GET_FAIL, Billing, USERS_GET_START, USERS_GET_SUCCESS, USERS_GET_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface BillingsListProps {
  className?: string;
  onDisplayInvoiceForm: (billing: Billing) => void;
  onDisplayPayForm: (billing: Billing) => void;
  onDisplayGiftForm: (billing: Billing) => void;
}

interface BillingsListFilters {
  pending: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const BillingsList = (props: BillingsListProps) => {
  const { onDisplayInvoiceForm, onDisplayPayForm, onDisplayGiftForm, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);
  const [filters, setFilters] = useState<BillingsListFilters>({ pending: true });

  const buildQueryParams = (curFilters: BillingsListFilters): string[] => {
    const pendingParam = [`pending=${curFilters.pending ? 'pending' : 'paid'}`];
    return pendingParam;
  }

  useEffect(() => {
    const fetchBillings = async () => {
      try {
        dispatch({type: BILLS_GET_START});
        const billsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/billings?${buildQueryParams(filters).join('&')}`,
          'GET',
          true,
        );
        dispatch({type: BILLS_GET_SUCCESS, payload: billsData});
      } catch (err: any) {
        dispatch({type: BILLS_GET_FAIL, payload: err.message});
      }
    }

    fetchBillings();
  }, [dispatch, filters]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch({type: USERS_GET_START});
        const usersData = await apiRequest(
          process.env.REACT_APP_API_URL + '/users/',
          'GET',
          true,
        );
        dispatch({type: USERS_GET_SUCCESS, payload: usersData});
      } catch (err: any) {
        dispatch({type: USERS_GET_FAIL, payload: err.message});
      }
    }

    fetchUsers();
  }, [dispatch]);

  const billingSorter = (billingA: Billing, billingB: Billing): number => {
    return billingB.creationDate.getTime() - billingA.creationDate.getTime();
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Utilisateur</TableCell>
          <TableCell>Objet</TableCell>
          <TableCell>Montant</TableCell>
          <TableCell>Statut</TableCell>
          <TableCell>Facture associée</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.billings.data.sort(billingSorter).map((bill: Billing, idx: number) => {
          const user = state.users.data.find(user => user._id === bill.user);
          return (
            <TableRow key={`bill_${idx.toFixed()}`}>
              <TableCell>{bill.creationDate.toLocaleDateString()}</TableCell>
              <TableCell>{user?.firstName} {user?.lastName}</TableCell>
              <TableCell>{bill.subject}</TableCell>
              <TableCell>{bill.amount.toFixed()} €</TableCell>
              <TableCell>
                {bill.gift ?
                  <React.Fragment>Offerte le {bill.paymentDate?.toLocaleDateString()}</React.Fragment>
                  : bill.paymentDate ?
                  <React.Fragment>Payée le {bill.paymentDate?.toLocaleDateString()}</React.Fragment>
                  : bill.invoiceDate ?
                  <React.Fragment>Facturée le {bill.invoiceDate?.toLocaleDateString()}</React.Fragment>
                  : <React.Fragment>Nouvelle</React.Fragment>
                }
              </TableCell>
              <TableCell>{bill.invoice}</TableCell>
              <TableCell>
                <ButtonGroup color="primary" variant="outlined" size="small">
                  {!bill.paymentDate && !bill.invoiceDate ?
                    <Button type="button" onClick={() => onDisplayInvoiceForm(bill)}>Facturé</Button>
                  : null}
                  {!bill.paymentDate && !bill.invoiceDate ?
                    <Button type="button" onClick={() => onDisplayGiftForm(bill)}>Offrir</Button>
                  : null}
                  {!bill.paymentDate && bill.invoiceDate ?
                    <Button type="button" onClick={() => onDisplayPayForm(bill)}>Payé</Button>
                  : null}
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Gestion des commandes / facturation" />
      <Divider />

      <CardContent>
        {state.billings.data.length > 0 ?
          renderTable() : <Alert severity="info">Aucune facture enregistrée</Alert>
        }
        {state.billings.error !== '' ?
          <Alert severity="error">{state.billings.error}</Alert> : null
        }
      </CardContent>

      <Divider />
      <CardActions>
        <Button
          type="button"
          onClick={() => setFilters({ pending: !filters.pending })}
        >{filters.pending ? 'Voir les commandes déjà facturées' : 'Retourner aux commandes en attente de facturation'}</Button>
      </CardActions>
    </Card>
  );
}

export default BillingsList;
