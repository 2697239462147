import React, { useContext, useEffect, useState } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { RadioGroup, Select, TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import TimeSelect from '../base/TimeSelect';

import { BKG_ADD_START, BKG_ADD_SUCCESS, BKG_ADD_FAIL, BKG_HALFDAYS, USER_GET_START, USER_GET_SUCCESS, USER_GET_FAIL, ROOM_GET_START, ROOM_GET_SUCCESS, ROOM_GET_FAIL, BKGRULE_GET_START, BKGRULE_GET_SUCCESS, BKGRULE_GET_FAIL, USERS_GET_START, USERS_GET_SUCCESS, USERS_GET_FAIL, Room, USERPKG_GET_START, USERPKG_GET_SUCCESS, USERPKG_GET_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { authContext } from '../../contexts/AuthContext';
import { apiRequest, unitText } from '../../utils/Helpers';

import BookingsView from '../admin/BookingsView';


interface BookingFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  adminView: boolean;
}

interface BookingFormValues {
  user?: string,
  room: string,
  rule: string,
  nbSpaces: number,
  comment: string,
  startDate: Date,
  startHour: number,
  startMinute: number,
  nbTimeUnits: number,
  halfDay?: string,
  endDate: Date,
  endHour: number,
  endMinute: number,
  amount: number,
  package: string,
};

interface UserPackageLight {
  _id: string,
  name: string,
  rule: string,
  validityEnd?: Date,
  remaining: number,
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const BookingForm = (props: BookingFormProps) => {
  const { adminView, onClose, className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  const [user, setUser] = useState(auth.auth?.user._id);
  const [userPackages, setUserPackages] = useState<UserPackageLight[]>([]);

  const emptyValues: BookingFormValues = {
    user: adminView ? '' : undefined,
    room: '',
    rule: '',
    nbSpaces: 1,
    comment: '',
    startDate: new Date(),
    startHour: (new Date()).getHours(),
    startMinute: 0,
    nbTimeUnits: 1,
    halfDay: 'AM',
    endDate: new Date(),
    endHour: (new Date()).getHours() + 1,
    endMinute: 0,
    amount: 1,
    package: '',
  };
  const initialValues: BookingFormValues = emptyValues;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        dispatch({type: USER_GET_START});
        const userData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/`,
          'GET',
          true,
        );
        dispatch({type: USER_GET_SUCCESS, payload: userData});
      } catch (err: any) {
        dispatch({type: USER_GET_FAIL, payload: err.message});
      }
    }

    fetchUser();
  }, [auth.auth, dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch({type: USERS_GET_START});
        const usersData = await apiRequest(
          process.env.REACT_APP_API_URL + '/users/',
          'GET',
          true,
        );
        dispatch({type: USERS_GET_SUCCESS, payload: usersData});
      } catch (err: any) {
        dispatch({type: USERS_GET_FAIL, payload: err.message});
      }
    }

    if (adminView) {
      fetchUsers();
    }
  }, [adminView, dispatch]);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        dispatch({type: ROOM_GET_START});
        const roomsData = await apiRequest(
          process.env.REACT_APP_API_URL + '/rooms/',
          'GET',
          true,
        );
        dispatch({type: ROOM_GET_SUCCESS, payload: roomsData});
      } catch (err: any) {
        dispatch({type: ROOM_GET_FAIL, payload: err.message});
      }
    }

    fetchRooms();
  }, [dispatch]);

  useEffect(() => {
    const fetchRules = async (forAdmin: boolean) => {
      try {
        dispatch({type: BKGRULE_GET_START});
        const url: string = forAdmin ? '/bookingRules/' : `/users/${auth.auth?.user._id}/bookingRules/`;
        const rulesData = await apiRequest(
          process.env.REACT_APP_API_URL + url,
          'GET',
          true,
        );
        dispatch({type: BKGRULE_GET_SUCCESS, payload: rulesData});
      } catch (err: any) {
        dispatch({type: BKGRULE_GET_FAIL, payload: err.message});
      }
    }

    fetchRules(adminView);
  }, [auth.auth, adminView, dispatch]);

  useEffect(() => {
    const fetchUserPackages = async () => {
      try {
        dispatch({type: USERPKG_GET_START});
        const packagesData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${user}/packages?active=active`,
          'GET',
          true,
        );
        dispatch({type: USERPKG_GET_SUCCESS, payload: packagesData});
      } catch (err: any) {
        dispatch({type: USERPKG_GET_FAIL, payload: err.message});
      }
    }

    fetchUserPackages();
  }, [user, dispatch]);

  const bookingMapper = (values: BookingFormValues): any => {
    const { startDate, startHour, startMinute, endDate, endHour, endMinute, amount, ...rest } = values;

    const rule = state.bookingRules.data.find(rule => rule._id === values.rule);
    const start = { startDate: moment(startDate).format('YYYY-MM-DD') };
    if ((adminView && !rule) || (rule && (rule.unit === 'HALF_AN_HOUR' || rule.unit === 'HOUR'))) {
      start.startDate = moment(startDate)
        .set({hour: startHour, minute: startMinute, second: 0, millisecond: 0}).toISOString();
    }

    const end = (adminView && !rule) ?
      { endDate: moment(endDate)
        .set({hour: endHour, minute: endMinute, second: 0, millisecond: 0}).toISOString()}
      : null;

    const booker = adminView ? { booker: auth.auth?.user._id} : null;
    const reqAmount = adminView ? { amount } : null;

    return Object.assign({}, rest, start, end, booker, reqAmount );
  }

  const creationHandler = async (values: BookingFormValues) => {
    try {
      dispatch({type: BKG_ADD_START});
      if (!(adminView && values.rule === '')) {
        // Check availability
        const availabilityData = await apiRequest(
          process.env.REACT_APP_API_URL + '/bookingSlots/availability',
          'POST',
          true,
          bookingMapper(values)
        );
        if (!availabilityData.available) {
          throw new Error('La salle n\'est pas disponible sur ce créneau avec ce nombre de places');
        }
      }

      // Do the booking
      const url: string = adminView ? '/bookings' : `/users/${auth.auth?.user._id}/bookings`;
      const bookingData = await apiRequest(
        process.env.REACT_APP_API_URL + url,
        'POST',
        true,
        bookingMapper(values)
      );
      dispatch({type: BKG_ADD_SUCCESS, payload: bookingData});
      onClose(true);
    } catch (err: any) {
      dispatch({type: BKG_ADD_FAIL, payload: err.message});
    }
  }

  const userField = (setFieldValue: any) => (
    <React.Fragment>
      <Grid item xs={12} sm={3}>
        <InputLabel htmlFor="user">Pour l'utilisateur</InputLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Field
          component={Select}
          id="user"
          name="user"
          native
          required
          onChange={(event: any) => handleUser(setFieldValue, event.target.value)}
          >
          <option key="user_void" value="">Sélectionner un utilisateur</option>
          {state.users.data.map((user, idx) =>
            <option key={`user_${idx.toFixed()}`} value={user._id}>{`${user.firstName} ${user.lastName}`}</option>
          )}
        </Field>
      </Grid>
    </React.Fragment>
  );

  const handleUser = (setFieldValue: any, userId: string) => {
    setUser(userId);
    setFieldValue('user', userId);
    setFieldValue('room', '');
    setFieldValue('rule', '');
  }

  const handleRoom = (setFieldValue: any, roomId: string) => {
    setFieldValue('room', roomId);
    setFieldValue('nbTimeUnits', 1);
    setFieldValue('rule', '');
    setFieldValue('package', '');
  }

  const handleRule = (setFieldValue: any, ruleId: string) => {
    setFieldValue('rule', ruleId);
    setFieldValue('nbTimeUnits', 1);

    const userPacks = state.userPackages.data
      .map(userPack => {
        return {
          _id: userPack._id,
          name: userPack.package.name,
          rule : userPack.package.rule,
          validityEnd: userPack.validityEnd,
          remaining: userPack.remaining,
        };
      })
      .filter(userPack => userPack.rule === ruleId);

    setUserPackages(userPacks);

    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);
    if (rule && rule.packageOnly && userPacks.length > 0) {
      setFieldValue('package', userPacks[0]._id);
    } else {
      setFieldValue('package', '');
    }
  }

  const ruleField = (roomId: string, setFieldValue: any) => (
    <Grid item xs={12} sm={5}>
      <Field
        component={Select}
        id="rule"
        name="rule"
        native
        required={!adminView}
        onChange={(event: any) => handleRule(setFieldValue, event.target.value)}
      >
        <option key="rule_void" value="">{adminView ? 'Réservation libre' : 'Sélectionner un type de réservation'}</option>
        {state.bookingRules.data.filter(rule => rule.room === roomId).map((rule, idx) =>
          <option key={`rule_${idx.toFixed()}`} value={rule._id}>
            {unitText(rule)}
          </option>
        )}
      </Field>
    </Grid>
  );

  const adminFields = () => (
    <React.Fragment>
      <Grid item xs={12} sm={3}>
        <InputLabel>Avertissement</InputLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <p>Attention : en mode de réservation libre, la vérification de la disponibilité de la salle vous appartient.</p>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel htmlFor="startDate">Début de la réservation</InputLabel>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          component={DatePicker}
          id="startDate"
          name="startDate"
          label="Date"
          required
          disablePast
          format="DD/MM/YYYY"
          disableToolbar
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimeSelect
          id="startTime"
          hourName="startHour"
          minuteName="startMinute"
          required
          minuteStep={30}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel htmlFor="endDate">Fin de la réservation</InputLabel>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          component={DatePicker}
          id="endDate"
          name="endDate"
          label="Date"
          required
          disablePast
          format="DD/MM/YYYY"
          disableToolbar
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimeSelect
          id="endTime"
          hourName="endHour"
          minuteName="endMinute"
          required
          minuteStep={30}
        />
      </Grid>
    </React.Fragment>
  );

  const unitFields = (ruleId: string) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if (rule) {
      if (rule.unit === 'HALF_AN_HOUR') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3}>
              <InputLabel htmlFor="startDate">Début de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TimeSelect
                id="startTime"
                hourName="startHour"
                minuteName="startMinute"
                required
                minuteStep={30}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="nbTimeUnits">Durée de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                type="number"
                id="nbTimeUnits"
                name="nbTimeUnits"
                required
                label="Durée"
                helperText="nombre de demi-heures"
              />
            </Grid>
          </React.Fragment>
        );
      } else if (rule.unit === 'HOUR') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3}>
              <InputLabel htmlFor="startDate">Début de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TimeSelect
                id="startTime"
                hourName="startHour"
                minuteName="startMinute"
                required
                minuteStep={30}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="nbTimeUnits">Durée de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                type="number"
                id="nbTimeUnits"
                name="nbTimeUnits"
                required
                label="Durée"
                helperText="nombre d'heures"
              />
            </Grid>
          </React.Fragment>
        );
      } else if (rule.unit === 'HALF_A_DAY') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3}>
              <InputLabel htmlFor="startDate">Date de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Field component={RadioGroup} name="halfDay" required>
              {BKG_HALFDAYS.map(half => (
                <FormControlLabel
                  key={`half_${half.name}`}
                  value={half.name}
                  label={half.text}
                  control={<Radio />}
                />
              ))}
              </Field>
            </Grid>
          </React.Fragment>
        );
      } else if (rule.unit === 'DAY') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="startDate">Date de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
          </React.Fragment>
        );
      } else if (rule.unit === 'WEEK') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="startDate">Premier jour de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="nbTimeUnits">Durée de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                type="number"
                id="nbTimeUnits"
                name="nbTimeUnits"
                required
                label="Durée"
                helperText="nombre de semaines"
              />
            </Grid>
          </React.Fragment>
        );
      } else if (rule.unit === 'MONTH') {
        return (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="startDate">Premier jour de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={DatePicker}
                id="startDate"
                name="startDate"
                label="Date"
                required
                disablePast
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="nbTimeUnits">Durée de la réservation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                type="number"
                id="nbTimeUnits"
                name="nbTimeUnits"
                required
                label="Durée"
                helperText="nombre de mois"
              />
            </Grid>
          </React.Fragment>
        );
      }
    }
  }

  const viewInfo = (roomId: string, ruleId: string, startDate: Date) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if ((adminView && !rule) || (rule && rule.unit !== 'MONTH' && rule.unit !== 'WEEK')) {
      const rooms = state.rooms.data.filter(room => room._id === roomId);
      const date = moment(startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate();

      return (
        <Grid item xs={12}>
          <BookingsView date={date} rooms={rooms} />
        </Grid>
      );
    }
  }

  const spacesField = (ruleId: string) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if (rule && rule.shareable) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="nbSpaces">Nombre de places</InputLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              component={TextField}
              type="number"
              id="nbSpaces"
              name="nbSpaces"
              required
              helperText={`Capacité maximale : ${rule.capacity} places`}
            />
          </Grid>
        </React.Fragment>
      );
    }
  }

  const packagesField = (ruleId: string, nbTimeUnits: number, nbSpaces: number) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if (rule && (rule.packageOnly || userPackages.length > 0)) {
      const nbUses: number = nbTimeUnits * nbSpaces;
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            {rule.packageOnly ? (
              <InputLabel htmlFor="package">J'utilise {nbUses.toFixed()} unité(s) de mon carnet</InputLabel>
            ) : (
              <InputLabel htmlFor="package">Je peux utiliser {nbUses.toFixed()} unité(s) de mon carnet</InputLabel>
            )}
          </Grid>
          <Grid item xs={12} sm={9}>
            <Field
              component={Select}
              id="package"
              name="package"
              native
              required={rule.packageOnly}
            >
              {rule.packageOnly && userPackages.length === 0 ? (
                <option key="pack_void" value="">Aucun carnet disponible</option>
              ) : null}
              {!rule.packageOnly ? (
                <option key="pack_void" value="">Je n'utilise pas de carnet</option>
              ) : null}
              {userPackages.length > 0 ? userPackages.map(userPack => (
                <option key={`pack_${userPack._id}`} value={userPack._id}>
                  {userPack.name} - reste {userPack.remaining} unités - Valide {userPack.validityEnd ? `jusqu'au ${moment(userPack.validityEnd).subtract(1, 'day').format('DD/MM/YYYY')}` : 'sans échéance'}
                </option>
              )) : null}
            </Field>
          </Grid>
        </React.Fragment>
      );
    }
  }

  const billingField = (ruleId: string, nbTimeUnits: number, nbSpaces: number) => {
    const rule = state.bookingRules.data.find(rule => rule._id === ruleId);

    if (!adminView && rule && !rule.packageOnly) {
      const amount = rule ? rule.price * nbTimeUnits * nbSpaces : 0;
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <InputLabel>Facturation</InputLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>Je devrais régler la somme de <strong>{amount.toFixed()} &euro;</strong></p>
          </Grid>
        </React.Fragment>
      );
    } else if (adminView && !(rule && rule.packageOnly)) {
      const amount = rule ? rule.price * nbTimeUnits * nbSpaces : 0;
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <InputLabel htmlFor="amount">Cette réservation sera facturée</InputLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              component={TextField}
              id="amount"
              name="amount"
              type="number"
              required
              label="Montant"
              helperText="€"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            {rule ? `Avec cette règle, le montant théorique est de ${amount.toFixed()} €` : null}
          </Grid>
        </React.Fragment>
      );
    }
  }

  const commentField = () => (
    <React.Fragment>
      <Grid item xs={12} sm={3}>
        <InputLabel htmlFor="comment">Je souhaite ajouter un commentaire à ma réservation</InputLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Field
          component={TextField}
          id="comment"
          name="comment"
          multiline
        />
      </Grid>
    </React.Fragment>
  );

  const filterAvailableRooms = (room: Room): boolean => {
    if (adminView) {
      return true;
    } else {
      return state.bookingRules.data.some(rule => rule.room === room._id);
    }
  }

  const renderForm = ({ isSubmitting, values, setFieldValue }: FormikProps<BookingFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Nouvelle réservation" subheader="La réservation ne pourra être modifiée que par un administrateur" />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            {adminView ? userField(setFieldValue) : null}

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="rule">Je souhaite réserver</InputLabel>
            </Grid>
            <Grid item xs={12} sm={values.room ? 4 : 9}>
              <Field
                component={Select}
                id="room"
                name="room"
                native
                required
                onChange={(event: any) => handleRoom(setFieldValue, event.target.value)}
              >
                <option key="room_void" value="">Sélectionner une salle</option>
                {state.rooms.data.filter(filterAvailableRooms).map((room, idx) =>
                  <option key={`room_${idx.toFixed()}`} value={room._id}>{room.name}</option>
                )}
              </Field>
            </Grid>

            {values.room !== '' ? ruleField(values.room, setFieldValue) : null}

            {adminView && values.room !== '' && values.rule === '' ? adminFields() : null}

            {values.rule !== '' ? unitFields(values.rule) : null}

            {values.room !== '' ? viewInfo(values.room, values.rule, values.startDate) : null}

            {values.rule !== '' ? spacesField(values.rule) : null}

            {values.rule !== '' ? packagesField(values.rule, values.nbTimeUnits, values.nbSpaces) : null}
            {(adminView || values.rule !== '') && values.package === '' ? billingField(values.rule, values.nbTimeUnits, values.nbSpaces) : null}

            {values.rule !== '' ? commentField() : null}
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Réserver</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.bookings.formError !== '' ?
            <Alert severity="error">{state.bookings.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (adminView && !values.user) {
            errors.user = 'Utilisateur requis';
          } else if (!values.room) {
            errors.room = 'Salle requise';
          } else if (!adminView && !values.rule) {
            errors.rule = 'Type de réservation requis';
          }
          return errors;
        }}
        onSubmit={(values) => creationHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default BookingForm;
