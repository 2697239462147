import React from 'react';

import { UserAuth } from "../types";
import useAuthHandler from '../hooks/AuthHandler';
import { getStoredUserAuth } from '../utils/Helpers';


interface IAuthContext {
  auth?: UserAuth;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

export const authContext = React.createContext<IAuthContext>({
  auth: undefined,
  setAuthStatus: () => {},
  setUnauthStatus: () => {},
})

const { Provider } = authContext;

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(
    getStoredUserAuth()
  );

  return (
    <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
      {children}
    </Provider>
  );
};

export default AuthProvider;
