import React, { useContext, useState } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { User, USERPKG_ADD_START, USERPKG_ADD_SUCCESS, USERPKG_ADD_FAIL, Package } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest, getStartOfToday } from '../../utils/Helpers';


interface UserPackageFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  user: User;
}

interface UserPackageFormValues {
  package: string,
  amount: number,
  alreadyUsed: number,
  comment: string,
  start?: Date,
  validityEnd?: Date,
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserPackageForm = (props: UserPackageFormProps) => {
  const { user, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const [computedValidityEnd, setComputedValidityEnd] = useState<Date | undefined>(undefined);

  const initialValues: UserPackageFormValues = {
    package: '',
    amount: 0,
    alreadyUsed: 0,
    comment: '',
  };

  const packageMapper = (values: UserPackageFormValues): any => {
    const { start, validityEnd, ...rest } = values;

    const cleanStart = start ? {
      start: moment(start).format('YYYY-MM-DD'),
    } : null;
    const cleanEnd = validityEnd ? {
      validityEnd: moment(validityEnd).format('YYYY-MM-DD'),
    } : null;

    return Object.assign({}, rest, cleanStart, cleanEnd);
  }

  const creationHandler = async (values: UserPackageFormValues) => {
    try {
      dispatch({type: USERPKG_ADD_START});
      const userPackageData = await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${user._id}/packages`,
        'POST',
        true,
        packageMapper(values)
      );
      dispatch({type: USERPKG_ADD_SUCCESS, payload: userPackageData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERPKG_ADD_FAIL, payload: err});
    }
  }

  const priceField = (packId: string) => {
    const pack = state.packages.data.find(pck => pck._id === packId);

    return (
      <React.Fragment>
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="amount">Ce carnet sera facturé</InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            component={TextField}
            id="amount"
            name="amount"
            type="number"
            required
            label="Montant"
            helperText="€"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          {pack ? `Le montant théorique est de ${pack.price.toFixed()} €` : null}
        </Grid>
      </React.Fragment>
    );
  }

  const validityFields = (values: UserPackageFormValues) => {
    const pack: Package | undefined = state.packages.data.find(pck => pck._id === values.package);

    if (pack !== undefined && pack.validity && pack.validity > 0) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <Field
              component={DatePicker}
              id="start"
              name="start"
              label="Début de validité"
              required
              format="DD/MM/YYYY"
              disableToolbar
              helperText="Date de début de validité incluse"
              onAccept={(date: Moment) => computeValidityEnd(pack, date.toDate())}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              component={DatePicker}
              id="validityEnd"
              name="validityEnd"
              label="Fin de validité"
              required
              format="DD/MM/YYYY"
              disableToolbar
              disablePast
              minDate={moment(values.start).add(1, 'day').toDate()}
              minDateMessage="La date de fin de validité doit être postérieure à la date de début"
              helperText="Date de fin de validité incluse"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            Avec cette date de début, la date de fin théorique est le {computedValidityEnd?.toLocaleDateString()}
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={12} sm={9}>
          <p>sans échéance</p>
        </Grid>
      );
    }
  }

  const handleValidity = (setFieldValue: any, packId: string) => {
    setFieldValue('package', packId)
    const pack: Package | undefined = state.packages.data.find(pck => pck._id === packId);

    if (pack !== undefined) {
      const start = getStartOfToday();
      setFieldValue('start', start);
      setFieldValue('validityEnd', computeValidityEnd(pack, start));
    } else {
      setFieldValue('start', undefined);
      setFieldValue('validityEnd', undefined);
    }
  }

  const computeValidityEnd = (pack: Package, date: Date): Date | undefined => {
    if (pack.validity && pack.validity > 0) {
      const computedDate = moment(date).add(pack.validity, 'week').subtract(1, 'day').toDate();
      setComputedValidityEnd(computedDate);
      return computedDate;
    } else {
      return undefined;
    }
  }

  const renderForm = ({ isSubmitting, values, setFieldValue }: FormikProps<UserPackageFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Ajout de carnet" />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="package">
                Pour l'utilisateur <strong>{user.firstName} {user.lastName}</strong>, je souhaite ajouter le carnet
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={Select}
                id="package"
                name="package"
                native
                required
                onChange={(event: any) => handleValidity(setFieldValue, event.target.value)}
              >
                <option key="pack_void" value="">Sélectionner le type de carnet</option>
                {state.packages.data.map((pack, idx) => {
                  return (
                    <option key={`pack_${idx.toFixed()}`} value={pack._id} >{pack.name}</option>
                  );
                })}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="alreadyUsed">Nombre d'unités déjà utilisées :</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                type="number"
                id="alreadyUsed"
                name="alreadyUsed"
                label="Déjà utilisé"
                helperText="Ce nombre sera décompté du carnet"
              />
            </Grid>

            {values.package !== '' ? priceField(values.package) : null}

            {values.package !== '' ? (
              <React.Fragment>
                <Grid item xs={12} sm={3}>
                  Validité
                </Grid>
                {validityFields(values)}
              </React.Fragment>
            ) : null}

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="comment">Commentaire</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                id="comment"
                name="comment"
                multiline
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Commander</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.userPackages.formError !== '' ?
            <Alert severity="error">{state.userPackages.formError}</Alert> : null}
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (!values.package) {
            errors.package = 'Type de carnet requis';
          } else if (values.validityEnd !== undefined && moment(values.start).isSameOrAfter(moment(values.validityEnd), 'day')) {
            errors.validityEnd = 'La date de fin de validité doit être postérieure à la date de début';
          }
          return errors;
        }}
        onSubmit={(values) => creationHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default UserPackageForm;
