import React, { useContext } from 'react';

import { Formik, Form, Field, FieldArray, FormikProps, ErrorMessage } from 'formik';
import { Select, TextField, CheckboxWithLabel } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import TimeSelect from '../base/TimeSelect';

import { BKGRULE_ADD_START, BKGRULE_ADD_FAIL, BKGRULE_ADD_SUCCESS, BKGRULE_UPDATE_START, BKGRULE_UPDATE_SUCCESS, BKGRULE_UPDATE_FAIL, BKG_UNITS, BKG_DAYS, BookingRule, Timeslot } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface BookingRuleFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  existing?: BookingRule;
}

interface BookingRuleValues {
  _id?: string;
  desc: string;
  room: string,
  unit: string,
  price: number,
  days: Array<string>,
  timeslots: Array<Timeslot>,
  shareable: boolean,
  capacity: number,
  validityStart: Date,
  validityEnd?: Date,
  adminOnly: boolean,
  packageOnly: boolean,
  cancellable: boolean,
  nbHoursCancellable?: number,
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const BookingRuleForm = (props: BookingRuleFormProps) => {
  const { onClose, existing, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const emptyValues: BookingRuleValues = {
    desc: '',
    room: '',
    unit: '',
    price: 0,
    days: [],
    timeslots: [],
    shareable: true,
    capacity: 1,
    validityStart: new Date(),
    validityEnd: undefined,
    adminOnly: false,
    packageOnly: false,
    cancellable: true,
    nbHoursCancellable: 24,
  };
  const initialValues: BookingRuleValues = existing ? {
    _id: existing._id,
    desc: existing.desc,
    room: existing.room,
    unit: existing.unit,
    price: existing.price,
    days: existing.days.map(day => day.toFixed()),
    timeslots: existing.timeslots,
    shareable: existing.shareable,
    capacity: existing.capacity,
    validityStart: existing.validityStart,
    validityEnd: existing.validityEnd ? existing.validityEnd : undefined,
    adminOnly: existing.adminOnly,
    packageOnly: existing.packageOnly,
    cancellable: existing.cancellable,
    nbHoursCancellable: existing.nbHoursCancellable ? existing.nbHoursCancellable : undefined,
  } : emptyValues;

  const ruleMapper = (values: BookingRuleValues): any => {
    const { days, validityStart, validityEnd, nbHoursCancellable, ...reqValues } = values;
    const reqDays = { days: days.map(day => parseInt(day, 10)) };
    const reqStart = { validityStart: moment(validityStart).format('YYYY-MM-DD') };
    const reqEnd = validityEnd !== undefined ? { validityEnd: moment(validityEnd).format('YYYY-MM-DD') } : null;
    const reqNbHoursCancel = reqValues.cancellable ? { nbHoursCancellable } : null;
    return Object.assign({}, reqValues, reqDays, reqStart, reqEnd, reqNbHoursCancel);
  }

  const creationHandler = async (values: BookingRuleValues) => {
    try {
      dispatch({type: BKGRULE_ADD_START});
      const ruleData = await apiRequest(
        process.env.REACT_APP_API_URL + '/bookingRules',
        'POST',
        true,
        ruleMapper(values)
      );
      dispatch({type: BKGRULE_ADD_SUCCESS, payload: ruleData});
      onClose(true);
    } catch (err) {
      dispatch({type: BKGRULE_ADD_FAIL, payload: err.message});
    }
  }

  const editionHandler = async (values: BookingRuleValues) => {
    try {
      dispatch({type: BKGRULE_UPDATE_START});
      const ruleData = await apiRequest(
        process.env.REACT_APP_API_URL + '/bookingRules/' + values._id,
        'PUT',
        true,
        ruleMapper(values)
      );
      dispatch({type: BKGRULE_UPDATE_SUCCESS, payload: ruleData});
      onClose(true);
    } catch (err) {
      dispatch({type: BKGRULE_UPDATE_FAIL, payload: err.message});
    }
  }

  const timeslotFields = (values: BookingRuleValues) => {
    return (
      <FieldArray
        name="timeslots"
        render={arrayHelpers => (
          <Grid container spacing={2}>
            {values.timeslots.length > 0 ? (
              values.timeslots.map((_, idx) => (
                <React.Fragment key={`slot_${idx}`}>
                  <Grid item xs={5} sm={4}>
                    De&nbsp;
                    <TimeSelect
                      id={`timeslots_${idx.toFixed()}_start`}
                      hourName={`timeslots.${idx.toFixed()}.startHour`}
                      minuteName={`timeslots.${idx.toFixed()}.startMinute`}
                      required
                      minuteStep={30}
                    />
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    à&nbsp;
                    <TimeSelect
                      id={`timeslots_${idx.toFixed()}_end`}
                      hourName={`timeslots.${idx.toFixed()}.endHour`}
                      minuteName={`timeslots.${idx.toFixed()}.endMinute`}
                      required
                      minuteStep={30}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => arrayHelpers.remove(idx)}
                    >x</Button>
                  </Grid>
                </React.Fragment>
              ))
            ) : ( null )
            }
            <Grid item xs={12}>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => arrayHelpers.push({ startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 })}
              >Ajouter un créneau</Button>

              {values.unit && (values.unit === 'HALF_AN_HOUR' || values.unit === 'HOUR') ?
                <p>Pour l'unité sélectionnée, le ou les créneaux horaires cadreront la possibilité de réservation (ces créneaux peuvent correspondant aux horaires d'ouverture, par exemple).</p> : null
              }
              {values.unit && (values.unit === 'HALF_A_DAY') ?
                <p>Pour l'unité sélectionnée, il est obligatoire de spécifier deux créneaux horaires : un pour la matinée, et un pour l'après-midi.</p> : null
              }
              {values.unit && (values.unit === 'DAY' || values.unit === 'WEEK' || values.unit === 'MONTH') ?
                <p>Pour l'unité sélectionnée, il est possible de spécifier un (et un seul) créneau horaire. Sans cette indication, les réservations iront de minuit à minuit.</p> : null
              }
              <ErrorMessage name="timeslots">
                {msg => <Alert severity="error">{msg}</Alert>}
              </ErrorMessage>
            </Grid>
          </Grid>
        )}
      />
    );
  }

  const renderForm = ({ isSubmitting, values, setFieldValue }: FormikProps<BookingRuleValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title={existing ? 'Modifier une règle de réservation' : 'Ajouter une règle de réservation'} />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel htmlFor="room">La salle</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field type="hidden" name="_id" />
              <Field
                component={Select}
                id="room"
                name="room"
                native
                required
              >
                <option key="room_void" value="">Sélectionner une salle</option>
                {state.rooms.data.map(room =>
                  <option key={`room_${room._id}`} value={room._id}>{room.name}</option>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel htmlFor="unit">est réservable</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                component={Select}
                id="unit"
                name="unit"
                native
                required
              >
                <option key="unit_void" value="">Sélectionner une unité</option>
                {BKG_UNITS.map(unit =>
                  <option key={`unit_${unit.name}`} value={unit.name}>{`${unit.pre} ${unit.text}`}</option>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="shareable">dans la disposition suivante</InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="shareable"
                Label={values.shareable ? { label: 'place par place (salle partagée)' } : { label : 'en entier (salle de réunion)' }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field
                component={TextField}
                type="number"
                id="capacity"
                name="capacity"
                label="Capacité"
                required
                helperText={values.shareable ? 'Nombre de places maximal' : 'A titre indicatif'}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="timeslots">
                {values.timeslots.length > 0 ? 'sur les créneaux horaires suivants' : 'toute la journée'}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              {timeslotFields(values)}
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="days">les jours suivants</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormGroup id="days" row>
                {BKG_DAYS.map(day =>
                  <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name="days"
                    key={`day_${day.nbISO.toFixed()}`}
                    value={day.nbISO.toFixed()}
                    Label={{ label: day.text }}
                  />
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel>Modalités de règlement</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={values.packageOnly ? 9 : 3}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="packageOnly"
                Label={values.packageOnly ? { label: 'uniquement en utilisant un carnet' } : { label : 'en utilisant un carnet ou en payant directement' }}
              />
            </Grid>
            { values.packageOnly ? null : (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={3}>
                  <InputLabel htmlFor="price">au prix de</InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} md={3}>
                  <Field
                    component={TextField}
                    type="number"
                    id="price"
                    name="price"
                    label="Prix"
                    required
                    helperText={`€ / ${BKG_UNITS.find(unit => unit.name === values.unit)?.text}`}
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel>Modalités d'annulation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={values.cancellable ? 3 : 9}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="cancellable"
                Label={values.cancellable ? { label: 'L\'utilisateur peut annuler sa propre réservation' } : { label: 'L\'utilisateur ne peut pas annuler sa propre réservation' }}
              />
            </Grid>
            { !values.cancellable ? null : (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={3}>
                  <InputLabel htmlFor="nbHoursCancellable">au plus tard</InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} md={3}>
                  <Field
                    component={TextField}
                    type="number"
                    id="nbHoursCancellable"
                    name="nbHoursCancellable"
                    label="période limite"
                    required={values.cancellable}
                    helperText="heures avant le début de la réservation"
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12} sm={3}>
              <InputLabel>Cette règle</InputLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="adminOnly"
                Label={values.adminOnly ? { label: 'ne peut être utilisée que par un administrateur' } : { label : 'est utilisable par tous les utilisateurs' }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field
                component={TextField}
                id="desc"
                name="desc"
                label="Description"
                required
                helperText="Précisions sur cette règle (visible par les utilisateurs)"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="validityStart">et est valide</InputLabel>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Field
                component={DatePicker}
                id="validityStart"
                name="validityStart"
                label="à partir du"
                required
                format="DD/MM/YYYY"
                disableToolbar
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              {values.validityEnd === undefined ? (
                <p>sans échéance.&nbsp;</p>
              ) : (
                <Field
                  component={DatePicker}
                  id="validityEnd"
                  name="validityEnd"
                  label="Fin de validité"
                  required
                  format="DD/MM/YYYY"
                  disableToolbar
                  minDate={moment(values.validityStart).add(1, 'day').toDate()}
                  minDateMessage="La date de fin de validité doit être postérieure à la date de début"
                  helperText="Date de fin de validité incluse"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {values.validityEnd === undefined ? (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setFieldValue('validityEnd', new Date())}
                >Ajouter date de fin</Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setFieldValue('validityEnd', undefined )}
                >x</Button>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Enregistrer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.bookingRules.formError !== '' ?
            <Alert severity="error">{state.bookingRules.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (!values.room) {
            errors.name = 'Champ salle requis';
          } else if (values.unit === 'HALF_A_DAY' && values.timeslots.length !== 2) {
            errors.timeslots = 'Il faut spécifier 2 créneaux horaires pour les réservations à la demi-journée.';
          } else if ((values.unit === 'DAY' || values.unit === 'WEEK' || values.unit === 'MONTH') && (values.timeslots.length > 1)) {
            errors.timeslots = 'Il n\'est pas possible de spécifier plus d\'un créneau horaire pour cette unité.';
          } else if (values.validityEnd !== undefined && moment(values.validityStart).isSameOrAfter(moment(values.validityEnd), 'day')) {
            errors.validityEnd = 'La date de fin de validité doit être postérieure à la date de début';
          }
          return errors;
        }}
        onSubmit={(values) => values._id ? editionHandler(values) : creationHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default BookingRuleForm;
