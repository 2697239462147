import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';

import { USERPKG_GET_START, USERPKG_GET_SUCCESS, USERPKG_GET_FAIL, BKG_GET_START, BKG_GET_SUCCESS, BKG_GET_FAIL, USER_GET_START, USER_GET_SUCCESS, USER_GET_FAIL, BKG_DELETE_START, BKG_DELETE_SUCCESS, BKG_DELETE_FAIL, UserPackage, Booking } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';

import UserForm from './UserForm';
import UserPackageForm from './UserPackageForm';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

interface UserParams {
  userId: string;
}

const UserDetails = () => {
  const classes = useStyles();

  const { userId } = useParams<UserParams>();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  const [ui, setUi] = useState({
    displayPackageForm: false,
    displayBookingForm: false,
    packageAddSuccess: false,
    bookingAddSuccess: false,
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        dispatch({type: USER_GET_START});
        const userData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${userId}`,
          'GET',
          true,
        );
        dispatch({type: USER_GET_SUCCESS, payload: userData});
      } catch (err) {
        dispatch({type: USER_GET_FAIL, payload: err});
      }
    }

    fetchUser();
  }, [dispatch, userId]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        dispatch({type: USERPKG_GET_START});
        const packagesData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${userId}/packages`,
          'GET',
          true,
        );
        dispatch({type: USERPKG_GET_SUCCESS, payload: packagesData});
      } catch (err) {
        dispatch({type: USERPKG_GET_FAIL, payload: err.message});
      }
    }

    fetchPackages();
  }, [dispatch, userId]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        dispatch({type: BKG_GET_START});
        const bookingsData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${userId}/bookings`,
          'GET',
          true,
        );
        dispatch({type: BKG_GET_SUCCESS, payload: bookingsData});
      } catch (err) {
        dispatch({type: BKG_GET_FAIL, payload: err});
      }
    }

    fetchBookings();
  }, [dispatch, userId]);

  const bookingDeletionHandler = async (id: string) => {
    try {
      dispatch({type: BKG_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + `/bookings/${id}`,
        'DELETE',
        true,
        { booker: auth.auth?.user._id }
      );
      dispatch({type: BKG_DELETE_SUCCESS, payload: id});
    } catch (err) {
      dispatch({type: BKG_DELETE_FAIL, payload: err.message});
    }
  }

  const resetUi = () => {
    setUi({
      displayBookingForm: false,
      displayPackageForm: false,
      bookingAddSuccess: false,
      packageAddSuccess: false,
    });
  }

  const handleClosedForm = (success: boolean = false) => {
    setUi({
      displayBookingForm: false,
      displayPackageForm: false,
      bookingAddSuccess: ui.displayBookingForm && success,
      packageAddSuccess: ui.displayPackageForm && success,
    });
  }

  const renderUserFormCard = () => {
    if (!state.user.isLoading) {
      if (state.user.error === '' && state.user.data !== undefined) {
        return (
          <UserForm
            onClose={() => {}}
            existing={state.user.data}
          />
        );
      } else {
        return (
          <Alert severity="error">
            Erreur lors du chargement des informations de l'utilisateur<br />
            {state.user.error}
          </Alert>
        );
      }
    } else {
      return null;
    }
  }

  const renderPackageForm = () => {
    if (ui.displayPackageForm && !state.user.isLoading) {
      if (state.user.error === '' && state.user.data !== undefined) {
        return (
          <Grid item xs={12}>
            <UserPackageForm
              key={`pack${userId}`}
              onClose={handleClosedForm}
              user={state.user.data}
            />
          </Grid>
        );
      }
    } else if (ui.packageAddSuccess) {
      return (
        <Grid item xs={12}>
          <Alert severity="success">Carnet ajouté avec succès</Alert>
        </Grid>
      );
    } else {
      return null;
    }
  }

  const userPackageSorter = (userPackA: UserPackage, userPackB: UserPackage): number => {
    return userPackB.start.getTime() - userPackA.start.getTime();
  }

  const renderPackagesTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Quantité restante</TableCell>
          <TableCell>Commence le</TableCell>
          <TableCell>Validité</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.userPackages.data.sort(userPackageSorter).map((userPack: UserPackage) => {
          const validity = userPack.validityEnd ?
            `jusqu'au ${moment(userPack.validityEnd).subtract(1, 'day').format('DD/MM/YYYY')} inclus`
            : 'sans limitation de durée';
          return (
            <TableRow key={userPack._id}>
              <TableCell>{userPack.package.name}</TableCell>
              <TableCell>{userPack.remaining.toFixed()}</TableCell>
              <TableCell>{moment(userPack.start).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{validity}</TableCell>
              <TableCell>
                <ButtonGroup color="primary" variant="outlined" size="small">
                  <Button
                    component={Link}
                    to={`/admin/users/${userId}/packages/${userPack._id}`}
                  >Détails</Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const renderPackages = () => {
    if (state.userPackages.isLoading) {
      return (
        <Alert severity="info">Chargement en cours</Alert>
      );
    } else {
      if (state.userPackages.loadingError !== '') {
        return (
          <Alert severity="error">
            Erreur lors du chargement des carnets<br />
            {state.userPackages.loadingError}
          </Alert>
        );
      } else if (state.userPackages.data.length === 0) {
        return (
          <Alert severity="info">Aucun carnet pour cet utilisateur</Alert>
        );
      } else {
        return renderPackagesTable();
      }
    }
  }

  const bookingSorter = (bookingA: Booking, bookingB: Booking): number => {
    return bookingB.timeslotStart.getTime() - bookingA.timeslotStart.getTime();
  }

  const renderBookingsTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Salle</TableCell>
          <TableCell>Heure de début</TableCell>
          <TableCell>Heure de fin</TableCell>
          <TableCell>Nombre de places</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {state.bookings.data.sort(bookingSorter).map((book, idx) =>
        <TableRow key={`book_${idx.toFixed()}`}>
          <TableCell>{state.rooms.data.find(rule => rule._id === book.room)?.name}</TableCell>
          <TableCell>{book.timeslotStart.toLocaleDateString()} {book.timeslotStart.toLocaleTimeString()}</TableCell>
          <TableCell>{book.timeslotEnd.toLocaleDateString()} {book.timeslotEnd.toLocaleTimeString()}</TableCell>
          <TableCell>{book.nbSpaces !== 0 ? book.nbSpaces.toFixed() : 'Toute la salle'}</TableCell>
          <TableCell>
            <Button
              type="button"
              color="primary" variant="outlined" size="small"
              onClick={() => bookingDeletionHandler(book._id)}
            >Annuler</Button>
          </TableCell>
        </TableRow>
      )}
      </TableBody>
    </Table>
  );

  const renderBookings = () => {
    if (state.bookings.isLoading) {
      return (
        <Alert severity="info">Chargement en cours</Alert>
      );
    } else {
      if (state.bookings.error !== '') {
        return (
          <Alert severity="error">
            Erreur lors du chargement des réservations<br />
            {state.bookings.error}
          </Alert>
        );
      } else if (state.bookings.data.length === 0) {
        return (
          <Alert severity="info">Aucune réservation enregistrée pour cet utilisateur</Alert>
        );
      } else {
        return renderBookingsTable();
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderUserFormCard()}
        </Grid>

        {renderPackageForm()}

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Carnets de l'utilisateur" />
            <Divider />

            <CardContent>
              {renderPackages()}
            </CardContent>

            <Divider />
            <CardActions>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => { resetUi(); setUi({...ui, displayPackageForm: true}); }}
              >Ajouter un carnet</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Réservations de l'utilisateur" />
            <Divider />

            <CardContent>
              {renderBookings()}
            </CardContent>

          </Card>
        </Grid>

      </Grid>
    </div>
  );
}

export default UserDetails;
