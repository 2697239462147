import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { UserPackage, User, USERPKG_UPDATE_START, USERPKG_UPDATE_SUCCESS, USERPKG_UPDATE_FAIL } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface UserPackageUseFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  user: User;
  pack: UserPackage;
}

interface UserPackageUseFormValues {
  _id: string;
  amount: number;
  date: Date;
  comment: String;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserPackageUseForm = (props: UserPackageUseFormProps) => {
  const { user, pack, onClose, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const initialValues: UserPackageUseFormValues = {
    _id: pack._id,
    amount: 1,
    date: new Date(),
    comment: '',
  };

  const usesAddHandler = async (values: UserPackageUseFormValues) => {
    try {
      dispatch({type: USERPKG_UPDATE_START});
      const userPackageData = await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${user._id}/packages/${pack._id}/uses`,
        'PUT',
        true,
        values
      );
      dispatch({type: USERPKG_UPDATE_SUCCESS, payload: userPackageData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERPKG_UPDATE_FAIL, payload: err});
    }
  }

  const renderForm = ({ isSubmitting }: FormikProps<UserPackageUseFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Ajouter une utilisation du carnet" />
        <Divider />

        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="amount">Nombre d'unités</InputLabel>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field type="hidden" name="_id" />
              <Field
                component={TextField}
                type="number"
                id="amount"
                name="amount"
                label="Unités"
                required
                helperText="Ce nombre sera décompté du carnet"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              Si vous souhaitez <strong>ajouter</strong> des unités au carnet, indiquez une valeur négative.
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="date">Date d'utilisation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={DatePicker}
                id="date"
                name="date"
                label="Date de l'utilisation"
                required
                format="DD MMM yyyy"
                disableToolbar
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="comment">Commentaire</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={TextField}
                id="comment"
                name="comment"
                multiline
              />
            </Grid>

          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Confirmer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.userPackages.formError !== '' ?
            <Alert severity="error">{state.userPackages.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (!values.date) {
            errors.date = 'Date d\'utilisation requise';
          } else if (!values.amount || values.amount === 0) {
            errors.amount = 'La quantité doit être différente de 0';
          }
          return errors;
        }}
        onSubmit={(values) => usesAddHandler(values)}
      >
        {renderForm}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}

export default UserPackageUseForm;
