import React, { useState } from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { apiRequest } from '../../utils/Helpers';

import logo from '../../assets/logo.webp';


interface ForgotPasswordValues {
  email: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    scale: 0.7,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const initialValues: ForgotPasswordValues = {
    email: '',
  };

  const forgotPasswordHandler = async (values: ForgotPasswordValues) => {
    try {
      setSuccess('');
      setError('');
      await apiRequest(
        process.env.REACT_APP_API_URL + '/auth/forgot',
        'POST',
        false,
        values
      );
      setSuccess('Un e-mail contenant un lien pour réinitialiser votre mot de passe vient d\'être envoyé.');
    } catch (_) {
      setError('Un problème s\'est produit pendant la réinitialisation de votre mot de passe. Merci de consulter l\'administrateur du site.');
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Link href="/" title="Revenir à l'accueil">
          <img src={logo} alt="Logo Good Place" className={classes.logo} />
        </Link>

        <Typography component="h1" variant="h5">Mot de passe oublié</Typography>

        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: any = {};
            if (!values.email) {
              errors.email = 'Champ requis';
            }
            return errors;
          }}
          onSubmit={(values) => forgotPasswordHandler(values)}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                id="email"
                type="email"
                name="email"
                label="Adresse e-mail"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                autoFocus
              />
              <br />

              {error !== '' ?
                <Alert severity="error">{error}</Alert>
              : null}
              {success !== '' ?
                <Alert severity='success'>{success}</Alert>
              : null}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >Réinitialiser mon mot de passe</Button>
            </Form>
          )}
        </Formik>

        <Typography component="p">
          <Link href="/" title="Revenir à la page de connexion" variant="button">
            Revenir à l'accueil
          </Link>
        </Typography>
      </div>
    </Container>
  );
}

export default ForgotPassword;
