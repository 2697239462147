import React, { useEffect, useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { storeContext } from '../../contexts/StoreContext';
import { USERPKG_GET_START, USERPKG_GET_SUCCESS, USERPKG_GET_FAIL, UserPackage, PackageUse } from '../../types';
import { apiRequest } from '../../utils/Helpers';

import UserPackageValidityForm from './UserPackageValidityForm';
import UserPackageUseForm from './UserPackageUseForm';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

interface PackageParams {
  userId: string;
  packageId: string;
}

const PackageDetails = () => {
  const classes = useStyles();

  const { userId, packageId } = useParams<PackageParams>();
  const [state, dispatch] = useContext(storeContext);

  const [ui, setUi] = useState({
    displayValidityForm: false,
    displayUseForm: false,
    validityEditSuccess: false,
    useAddSuccess: false,
  });

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        dispatch({type: USERPKG_GET_START});
        const packagesData = await apiRequest(
          process.env.REACT_APP_API_URL + `/users/${userId}/packages`,
          'GET',
          true,
        );
        dispatch({type: USERPKG_GET_SUCCESS, payload: packagesData});
      } catch (err) {
        dispatch({type: USERPKG_GET_FAIL, payload: err.message});
      }
    }

    if (!state.userPackages.data.find(userPack => userPack._id === packageId)) {
      fetchPackages();
    }
  }, [dispatch, userId, packageId, state.userPackages]);

  const resetUi = () => {
    setUi({
      displayValidityForm: false,
      displayUseForm: false,
      validityEditSuccess: false,
      useAddSuccess: false,
    });
  }

  const handleClosedForm = (success: boolean = false) => {
    setUi({
      displayValidityForm: false,
      displayUseForm: false,
      validityEditSuccess: ui.displayValidityForm && success,
      useAddSuccess: ui.displayUseForm && success,
    });
  }

  const packageUsesSorter = (useA: PackageUse, useB: PackageUse): number => {
    return useB.date.getTime() - useA.date.getTime();
  }

  const renderValidityForm = () => {
    const userPackage = state.userPackages.data.find(userPack => userPack._id === packageId);

    if (ui.displayValidityForm && userPackage && state.user.data !== undefined) {
      return (
        <Grid item xs={12}>
          <UserPackageValidityForm
            key={`pack${packageId}`}
            onClose={handleClosedForm}
            user={state.user.data}
            pack={userPackage}
          />
        </Grid>
      );
    } else if (ui.validityEditSuccess) {
      return (
        <Grid item xs={12}>
          <Alert severity="success">Dates de validité modifiées avec succès</Alert>
        </Grid>
      );
    } else {
      return null;
    }
  }

  const renderUsesForm = () => {
    const userPackage = state.userPackages.data.find(userPack => userPack._id === packageId);

    if (ui.displayUseForm && userPackage && state.user.data !== undefined) {
      return (
        <Grid item xs={12}>
          <UserPackageUseForm
            key={`packuse${packageId}`}
            onClose={handleClosedForm}
            user={state.user.data}
            pack={userPackage}
          />
        </Grid>
      );
    } else if (ui.useAddSuccess) {
      return (
        <Grid item xs={12}>
          <Alert severity="success">Unités ajoutées / supprimées avec succès</Alert>
        </Grid>
      );
    } else {
      return null;
    }
  }

  const renderPackageHistoryTable = (userPackage: UserPackage) => (
    <Table size="small">
      <TableBody>
      {userPackage.validityEnd ? (
        <TableRow key={"pack_validityEnd"}>
          <TableCell colSpan={2}>
            Fin de validité du carnet le {moment(userPackage.validityEnd).subtract(1, 'day').format('DD/MM/YYYY')}
          </TableCell>
        </TableRow>
      ) : null}
        <TableRow key={"pack_recap"}>
          <TableCell colSpan={2}>{userPackage.remaining} unités restantes</TableCell>
        </TableRow>
      {userPackage.uses.sort(packageUsesSorter).map((use: PackageUse, idx: number) =>
        <TableRow key={`pack_use_${idx.toFixed()}`}>
          <TableCell>{use.date.toLocaleDateString()}</TableCell>
          <TableCell>{use.comment !== '' ? use.comment : `Réservation de ${use.amount} unité(s)`}</TableCell>
        </TableRow>
      )}
        <TableRow key={"pack_first"}>
          <TableCell colSpan={2}>
            Début de validité du carnet le {userPackage.start.toLocaleDateString()}
          </TableCell>
        </TableRow>
        <TableRow key={"pack_billing"}>
          <TableCell colSpan={2}>
            Achat du carnet le {userPackage.billing.creationDate.toLocaleDateString()}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )

  const renderPackageHistory = () => {
    if (state.userPackages.isLoading) {
      return (
        <Alert severity="info">Chargement en cours</Alert>
      );
    } else {
      const userPackage = state.userPackages.data.find(userPack => userPack._id === packageId);
      if (state.userPackages.loadingError !== '' || !userPackage) {
        return (
          <Alert severity="error">
            Erreur lors du chargement des carnets<br />
            {state.userPackages.loadingError}
          </Alert>
        );
      } else {
        return renderPackageHistoryTable(userPackage);
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Détails du carnet" />
            <Divider />

            {renderValidityForm()}

            {renderUsesForm()}

            <CardContent>
              {renderPackageHistory()}
            </CardContent>

            <Divider />
            <CardActions>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                component={Link}
                to={`/admin/users/${userId}`}
              >Retour à la fiche de l'utilisateur</Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => { resetUi(); setUi({...ui, displayValidityForm: true}); }}
              >Modifier la date de fin de validité</Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => { resetUi(); setUi({...ui, displayUseForm: true}); }}
              >Ajouter / supprimer des unités</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default PackageDetails;
