// Naming
export const BKG_UNITS: Array<{name: string, text: string, pre: string}> = [
  { name: 'HALF_AN_HOUR', text: 'demi-heure', pre: 'à la ' },
  { name: 'HOUR', text: 'heure', pre: 'à l\'' },
  { name: 'HALF_A_DAY', text: 'demi-journée', pre: 'à la ' },
  { name: 'DAY', text: 'journée', pre: 'à la ' },
  { name: 'WEEK', text: 'semaine', pre: 'à la ' },
  { name: 'MONTH', text: 'mois', pre: 'au ' },
];
export const BKG_DAYS: Array<{nbISO: number, text: string}> = [
  { nbISO: 1, text: 'lundi' },
  { nbISO: 2, text: 'mardi' },
  { nbISO: 3, text: 'mercredi' },
  { nbISO: 4, text: 'jeudi' },
  { nbISO: 5, text: 'vendredi' },
  { nbISO: 6, text: 'samedi' },
  { nbISO: 7, text: 'dimanche' },
];
export const BKG_HALFDAYS: Array<{name: string, text: string}> = [
  { name: 'AM', text: 'matin' },
  { name: 'PM', text: 'après-midi' },
];

export const BKG_EVENTS: Array<{name: string, text: string}> = [
  { name: 'CREATION', text: 'Création' },
  { name: 'EDITION', text: 'Modification' },
  { name: 'DELETION', text: 'Suppression' },
];

// Models
export type Billing = {
  _id: string,
  user: string,
  subject: string,
  amount: number,
  invoice: string,
  gift: boolean,
  creationDate: Date,
  invoiceDate?: Date,
  paymentDate?: Date,
  comment?: string,
};
export const billingMapper = (billing: any): Billing => {
  return {
    ...billing,
    creationDate: new Date(billing.creationDate),
    invoiceDate: billing.invoiceDate ? new Date(billing.invoiceDate) : undefined,
    paymentDate: billing.paymentDate ? new Date(billing.paymentDate) : undefined,
  };
}

export type Package = {
  _id: string,
  name: string,
  desc: string,
  rule: string,
  quantity: number,
  price: number,
  validity: number,
};

export type PackageUse = {
  _id: string,
  amount: number,
  date: Date,
  comment: string,
};
export const packageUseMapper = (use: any): PackageUse => {
  return {
    ...use,
    date: new Date(use.date),
  };
}

export type UserPackage = {
  _id: string,
  package: Package,
  billing: Billing,
  uses: Array<PackageUse>,
  remaining: number,
  start: Date,
  validityEnd?: Date,
};
export const userPackageMapper = (userPackage: any): UserPackage => {
  const nbTotal = userPackage.package.quantity;
  const uses: Array<PackageUse> = userPackage.uses.map(packageUseMapper);
  const remaining: number = uses.reduce((acc: number, curr: PackageUse) => acc - curr.amount, nbTotal);
  return {
    ...userPackage,
    billing: billingMapper(userPackage.billing),
    uses,
    remaining,
    start: new Date(userPackage.start),
    validityEnd: userPackage.validityEnd ? new Date(userPackage.validityEnd) : undefined,
  };
}

export type User = {
  _id: string,
  email: string,
  firstName: string,
  lastName: string,
  pwdHash: string,
  roles: Array<string>,
};

export type UserAuth = {
  user: User,
  token: string,
};

export type Room = {
  _id: string,
  name: string,
  desc: string,
};

export type Timeslot = {
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
};

export type BookingRule = {
  _id: string,
  desc: string,
  room: string,
  unit: string,
  price: number,
  days: Array<number>,
  timeslots: Array<Timeslot>,
  shareable: boolean,
  capacity: number,
  validityStart: Date,
  validityEnd?: Date,
  adminOnly: boolean,
  packageOnly: boolean,
  cancellable: boolean,
  nbHoursCancellable: number,
};

export type BookingEvent = {
  user: string,
  type: string,
  date: Date,
  comment: string,
  data: {
    nbSpaces: number,
    startDate: Date,
    endDate?: Date,
    halfDay?: string,
    nbTimesUnits?: number,
  },
};

export type CreationBooking = {
  _id: string,
  rule: string,
  nbSpaces: number,
  comment: string,
  startDate: Date,
  halfDay?: string,
  nbTimeUnits?: number,
  events: Array<BookingEvent>,
  package?: string,
  billing?: string,
};

export type Booking = {
  _id: string,
  user: string,
  room: string,
  rule?: string,
  nbSpaces: number,
  timeslotStart: Date,
  timeslotEnd: Date,
  events: Array<BookingEvent>,
  package?: string,
  billing?: Billing,
  userCancellable?: boolean,
};
export const bookingMapper = (booking: any): Booking => {
  return {
    ...booking,
    timeslotStart: new Date(booking.timeslotStart),
    timeslotEnd: new Date(booking.timeslotEnd),
  };
}

export type BookingSlot = {
  _id: string,
  room: string,
  user: string,
  userName: string,
  timeslotStart: Date,
  timeslotEnd: Date,
  nbSpaces: number,
};
export const bookingSlotMapper = (slot: any): BookingSlot => {
  return {
    ...slot,
    timeslotStart: new Date(slot.timeslotStart),
    timeslotEnd: new Date(slot.timeslotEnd),
  };
}


// States
export interface UserState {
  isLoading: boolean,
  data?: User;
  error: string;
  formError: string;
}
export interface RoomsState {
  isLoading: boolean;
  data: Array<Room>;
  error: string;
  formError: string;
}
export interface BookingRulesState {
  isLoading: boolean;
  data: Array<BookingRule>;
  error: string;
  formError: string;
}
export interface UsersState {
  isLoading: boolean;
  data: Array<User>;
  loadingError: string;
  formError: string;
}
export interface PackagesState {
  isLoading: boolean;
  data: Array<Package>;
  error: string;
  formError: string;
}
export interface BookingsState {
  isLoading: boolean,
  data: Array<Booking>;
  error: string;
  formError: string;
}
export interface BillingsState {
  isLoading: boolean,
  data: Array<Billing>;
  error: string;
  formError: string;
}
export interface UserPackagesState {
  isLoading: boolean;
  data: Array<UserPackage>;
  loadingError: string;
  formError: string;
}

export interface Store {
  user: UserState;
  rooms: RoomsState;
  bookingRules: BookingRulesState;
  users: UsersState;
  packages: PackagesState;
  bookings: BookingsState;
  billings: BillingsState;
  userPackages: UserPackagesState;
}

// Actions
export interface Action {
  type: string;
  payload?: any;
}

export const USER_GET_START: string = 'USER_GET_START';
export const USER_GET_SUCCESS: string = 'USER_GET_SUCCESS';
export const USER_GET_FAIL: string = 'USER_GET_FAIL';
export const USER_UPDATE_START: string = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS: string = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL: string = 'USER_UPDATE_FAIL';

export const USERPKG_ADD_START: string = 'USERPKG_ADD_START';
export const USERPKG_ADD_SUCCESS: string = 'USERPKG_ADD_SUCCESS';
export const USERPKG_ADD_FAIL: string = 'USERPKG_ADD_FAIL';
export const USERPKG_GET_START: string = 'USERPKG_GET_START';
export const USERPKG_GET_SUCCESS: string = 'USERPKG_GET_SUCCESS';
export const USERPKG_GET_FAIL: string = 'USERPKG_GET_FAIL';
export const USERPKG_UPDATE_START: string = 'USERPKG_UPDATE_START';
export const USERPKG_UPDATE_SUCCESS: string = 'USERPKG_UPDATE_SUCCESS';
export const USERPKG_UPDATE_FAIL: string = 'USERPKG_UPDATE_FAIL';

export const USERS_ADD_START: string = 'USERS_ADD_START';
export const USERS_ADD_SUCCESS: string = 'USERS_ADD_SUCCESS';
export const USERS_ADD_FAIL: string = 'USERS_ADD_FAIL';
export const USERS_GET_START: string = 'USERS_GET_START';
export const USERS_GET_SUCCESS: string = 'USERS_GET_SUCCESS';
export const USERS_GET_FAIL: string = 'USERS_GET_FAIL';
export const USERS_UPDATE_START: string = 'USERS_UPDATE_START';
export const USERS_UPDATE_SUCCESS: string = 'USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_FAIL: string = 'USERS_UPDATE_FAIL';
export const USERS_DELETE_START: string = 'USERS_DELETE_START';
export const USERS_DELETE_SUCCESS: string = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAIL: string = 'USERS_DELETE_FAIL';

export const ROOM_ADD_START: string = 'ROOM_ADD_START';
export const ROOM_ADD_SUCCESS: string = 'ROOM_ADD_SUCCESS';
export const ROOM_ADD_FAIL: string = 'ROOM_ADD_FAIL';
export const ROOM_GET_START: string = 'ROOM_GET_START';
export const ROOM_GET_SUCCESS: string = 'ROOM_GET_SUCCESS';
export const ROOM_GET_FAIL: string = 'ROOM_GET_FAIL';
export const ROOM_UPDATE_START: string = 'ROOM_UPDATE_START';
export const ROOM_UPDATE_SUCCESS: string = 'ROOM_UPDATE_SUCCESS';
export const ROOM_UPDATE_FAIL: string = 'ROOM_UPDATE_FAIL';
export const ROOM_DELETE_START: string = 'ROOM_DELETE_START';
export const ROOM_DELETE_SUCCESS: string = 'ROOM_DELETE_SUCCESS';
export const ROOM_DELETE_FAIL: string = 'ROOM_DELETE_FAIL';

export const BKG_ADD_START: string = 'BKG_ADD_START';
export const BKG_ADD_SUCCESS: string = 'BKG_ADD_SUCCESS';
export const BKG_ADD_FAIL: string = 'BKG_ADD_FAIL';
export const BKG_GET_START: string = 'BKG_GET_START';
export const BKG_GET_SUCCESS: string = 'BKG_GET_SUCCESS';
export const BKG_GET_FAIL: string = 'BKG_GET_FAIL';
export const BKG_UPDATE_START: string = 'BKG_UPDATE_START';
export const BKG_UPDATE_SUCCESS: string = 'BKG_UPDATE_SUCCESS';
export const BKG_UPDATE_FAIL: string = 'BKG_UPDATE_FAIL';
export const BKG_DELETE_START: string = 'BKG_DELETE_START';
export const BKG_DELETE_SUCCESS: string = 'BKG_DELETE_SUCCESS';
export const BKG_DELETE_FAIL: string = 'BKG_DELETE_FAIL';

export const BKGRULE_ADD_START: string = 'BKGRULE_ADD_START';
export const BKGRULE_ADD_SUCCESS: string = 'BKGRULE_ADD_SUCCESS';
export const BKGRULE_ADD_FAIL: string = 'BKGRULE_ADD_FAIL';
export const BKGRULE_GET_START: string = 'BKGRULE_GET_START';
export const BKGRULE_GET_SUCCESS: string = 'BKGRULE_GET_SUCCESS';
export const BKGRULE_GET_FAIL: string = 'BKGRULE_GET_FAIL';
export const BKGRULE_UPDATE_START: string = 'BKGRULE_UPDATE_START';
export const BKGRULE_UPDATE_SUCCESS: string = 'BKGRULE_UPDATE_SUCCESS';
export const BKGRULE_UPDATE_FAIL: string = 'BKGRULE_UPDATE_FAIL';
export const BKGRULE_DELETE_START: string = 'BKGRULE_DELETE_START';
export const BKGRULE_DELETE_SUCCESS: string = 'BKGRULE_DELETE_SUCCESS';
export const BKGRULE_DELETE_FAIL: string = 'BKGRULE_DELETE_FAIL';

export const PKG_ADD_START: string = 'PKG_ADD_START';
export const PKG_ADD_SUCCESS: string = 'PKG_ADD_SUCCESS';
export const PKG_ADD_FAIL: string = 'PKG_ADD_FAIL';
export const PKG_GET_START: string = 'PKG_GET_START';
export const PKG_GET_SUCCESS: string = 'PKG_GET_SUCCESS';
export const PKG_GET_FAIL: string = 'PKG_GET_FAIL';
export const PKG_UPDATE_START: string = 'PKG_UPDATE_START';
export const PKG_UPDATE_SUCCESS: string = 'PKG_UPDATE_SUCCESS';
export const PKG_UPDATE_FAIL: string = 'PKG_UPDATE_FAIL';
export const PKG_DELETE_START: string = 'PKG_DELETE_START';
export const PKG_DELETE_SUCCESS: string = 'PKG_DELETE_SUCCESS';
export const PKG_DELETE_FAIL: string = 'PKG_DELETE_FAIL';

export const BILLS_ADD_START: string = 'BILLS_ADD_START';
export const BILLS_ADD_SUCCESS: string = 'BILLS_ADD_SUCCESS';
export const BILLS_ADD_FAIL: string = 'BILLS_ADD_FAIL';
export const BILLS_GET_START: string = 'BILLS_GET_START';
export const BILLS_GET_SUCCESS: string = 'BILLS_GET_SUCCESS';
export const BILLS_GET_FAIL: string = 'BILLS_GET_FAIL';
export const BILLS_UPDATE_START: string = 'BILLS_UPDATE_START';
export const BILLS_UPDATE_SUCCESS: string = 'BILLS_UPDATE_SUCCESS';
export const BILLS_UPDATE_FAIL: string = 'BILLS_UPDATE_FAIL';
export const BILLS_DELETE_START: string = 'BILLS_DELETE_START';
export const BILLS_DELETE_SUCCESS: string = 'BILLS_DELETE_SUCCESS';
export const BILLS_DELETE_FAIL: string = 'BILLS_DELETE_FAIL';
