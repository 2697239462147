import { Action, PKG_GET_START, Package, PackagesState, PKG_ADD_START, PKG_DELETE_START, PKG_GET_SUCCESS, PKG_GET_FAIL, PKG_ADD_SUCCESS, PKG_ADD_FAIL, PKG_UPDATE_START, PKG_UPDATE_SUCCESS, PKG_UPDATE_FAIL, PKG_DELETE_SUCCESS, PKG_DELETE_FAIL } from "../types";

const packagesReducer = (state: PackagesState, action: Action): PackagesState => {
  switch (action.type) {
    case PKG_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case PKG_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(action.payload as Package),
        formError: '',
      };
    case PKG_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case PKG_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case PKG_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as Array<Package>),
        error: '',
      };
    case PKG_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case PKG_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case PKG_UPDATE_SUCCESS:
      const updatedPackage = (action.payload as Package);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(pack => {
          return (pack._id === updatedPackage._id) ? updatedPackage : pack;
        }),
        formError: '',
      };
    case PKG_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case PKG_DELETE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case PKG_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(type => type._id !== (action.payload as string)),
      };
    case PKG_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    default:
      return state;
  }
}

export default packagesReducer;
