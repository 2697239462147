import React, { useState, useContext } from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { apiRequest } from '../../utils/Helpers';
import { authContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/logo.webp';

interface LoginValues {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    scale: 0.7,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const auth = useContext(authContext);
  const [error, setError] = useState('');
  const history = useHistory();

  const initialValues: LoginValues = {
    email: '',
    password: '',
  };

  const loginHandler = async (values: LoginValues) => {
    try {
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + '/auth/login',
        'POST',
        false,
        values
      );
      const { user, token } = userData;
      auth.setAuthStatus({ user, token });
      history.push('/profile');
    } catch (_) {
      setError('E-mail ou mot de passe incorrect');
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Link href="/" title="Revenir à l'accueil">
          <img src={logo} alt="Logo Good Place" className={classes.logo} />
        </Link>

        <Typography component="h1" variant="h5">Connexion</Typography>

        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: any = {};
            if (!values.email) {
              errors.email = 'Champ requis';
            } else if (!values.password) {
              errors.password = 'Champ requis';
            }
            return errors;
          }}
          onSubmit={(values) => loginHandler(values)}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                id="email"
                type="email"
                name="email"
                label="Adresse e-mail"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                autoFocus
              />
              <br />

              <Field
                component={TextField}
                id="password"
                type="password"
                name="password"
                label="Mot de passe"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <br />

              {error !== '' ?
                <Alert severity="error">{error}</Alert>
              : null}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >Se connecter</Button>
            </Form>
          )}
        </Formik>

        <Typography component="p">
          <Link href="/register" title="Page de création de compte" variant="button">
            Créer un compte
          </Link>
        </Typography>
        <Typography component="p">
          <Link href="/forgotPassword" title="Réinitialiser votre mot de passe" variant="button">
            Mot de passe oublié
          </Link>
        </Typography>
      </div>
    </Container>
  );
}

export default Login;
