import { Action, BKGRULE_GET_START, BookingRule, BookingRulesState, BKGRULE_ADD_START, BKGRULE_DELETE_START, BKGRULE_GET_SUCCESS, BKGRULE_GET_FAIL, BKGRULE_ADD_SUCCESS, BKGRULE_ADD_FAIL, BKGRULE_UPDATE_START, BKGRULE_UPDATE_SUCCESS, BKGRULE_UPDATE_FAIL, BKGRULE_DELETE_SUCCESS, BKGRULE_DELETE_FAIL } from "../types";

const rulesReducer = (state: BookingRulesState, action: Action): BookingRulesState => {
  switch (action.type) {
    case BKGRULE_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BKGRULE_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(action.payload as BookingRule),
        formError: '',
      };
    case BKGRULE_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BKGRULE_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BKGRULE_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as Array<BookingRule>),
        error: '',
      };
    case BKGRULE_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case BKGRULE_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BKGRULE_UPDATE_SUCCESS:
      const updatedBookingRule = (action.payload as BookingRule);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(rule => {
          return (rule._id === updatedBookingRule._id) ? updatedBookingRule : rule;
        }),
        formError: '',
      };
    case BKGRULE_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BKGRULE_DELETE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BKGRULE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(type => type._id !== (action.payload as string)),
      };
    case BKGRULE_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    default:
      return state;
  }
}

export default rulesReducer;
