import React, { useContext, useState } from 'react';
import { Moment } from 'moment';

import { Formik, Form, Field, FormikProps } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { storeContext } from '../../contexts/StoreContext';
import { getStartOfToday } from '../../utils/Helpers';

import BookingsView from './BookingsView';
import BookingsList from './BookingsList';
import BookingForm from '../user/BookingForm';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const BookingsAdmin = () => {
  const classes = useStyles();
  const [state] = useContext(storeContext);
  const [date, setDate] = useState(getStartOfToday())
  const [displayForm, setDisplayForm] = useState(false);
  const [success, setSuccess] = useState(false);

  const renderForm = ({ isSubmitting }: FormikProps<{date: Date}>) => (
    <Form>
      <Field
        component={DatePicker}
        id="date"
        name="date"
        label="Date"
        required
        onAccept={(date: Moment) => setDate(date.toDate())}
      />
    </Form>
  );

  const handleClosedForm = (success?: boolean) => {
    setDisplayForm(false);
    if (success) {
      setSuccess(true);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {displayForm ? (
          <Grid item xs={12}>
            <BookingForm
              key="booknew"
              onClose={handleClosedForm}
              adminView={true}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <BookingsList onDisplayForm={() => { setDisplayForm(true); setSuccess(false); }} />
        </Grid>

        {success ? (
          <Grid item xs={12}>
            <Alert severity="success">Réservation enregistrée avec succès</Alert>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Card>
              <CardHeader title="Planning quotidien" />
              <Divider />

              <CardContent>
                <BookingsView date={date} rooms={state.rooms.data} fullView={true} />
              </CardContent>

              <Divider />
              <CardActions>
                <Formik
                  initialValues={{date: getStartOfToday()}}
                  validate={values => {
                    const errors: any = {};
                    if (!values.date) {
                      errors.date = 'Date requise';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => { setDate(values.date); setSubmitting(false); }}
                >
                  {renderForm}
                </Formik>
              </CardActions>
            </Card>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  );
}

export default BookingsAdmin;
