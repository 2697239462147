import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import PackageForm from './PackageForm';
import PackagesList from './PackagesList';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const Packages = () => {
  const classes = useStyles();

  const [displayForm, setDisplayForm] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClosedForm = (success?: boolean) => {
    setDisplayForm(false);
    if (success) {
      setSuccess(true);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {displayForm ? (
          <Grid item xs={12}>
            <PackageForm
              key="booknew"
              onClose={handleClosedForm}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <PackagesList onDisplayForm={() => {setDisplayForm(true); setSuccess(false); }} />
        </Grid>

        {success ? (
          <Grid item xs={12}>
            <Alert severity="success">Carnet commandé avec succès</Alert>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default Packages;
