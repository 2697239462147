import React, { useState, useContext } from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { apiRequest } from '../../utils/Helpers';
import { authContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/logo.webp';

interface SigninValues {
  email: string;
  firstName: string;
  lastName: string;
  pwd: string;
  pwdCheck: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    scale: 0.7,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signin = () => {
  const classes = useStyles();
  const auth = useContext(authContext);
  const [error, setError] = useState('');
  const history = useHistory();

  const initialValues: SigninValues = {
    email: '',
    firstName: '',
    lastName: '',
    pwd: '',
    pwdCheck: '',
  };

  const signinHandler = async (values: SigninValues) => {
    try {
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + '/auth/register',
        'POST',
        false,
        values
      );
      const { user, token } = userData;
      auth.setAuthStatus({ user, token });
      history.push('/profile');
    } catch (err: any) {
      setError(err.message ?? 'Un problème inconnu s\'est produit');
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Link href="/" title="Revenir à l'accueil">
          <img src={logo} alt="Logo Good Place" className={classes.logo} />
        </Link>

        <Typography component="h1" variant="h5">Nouveau compte</Typography>

        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: any = {};
            if (!values.email) {
              errors.email = 'Champ requis';
            } else if (!values.firstName) {
              errors.firstName = 'Champ requis';
            } else if (!values.lastName) {
              errors.lastName = 'Champ requis';
            } else if (!values.pwd) {
              errors.pwd = 'Champ requis';
            } else if (!values.pwdCheck) {
              errors.pwdCheck = 'Champ requis';
            } else if (values.pwd !== values.pwdCheck) {
              errors.pwdCheck = 'Le mot de passe ne correspond pas à la confirmation'
            }
            return errors;
          }}
          onSubmit={(values) => signinHandler(values)}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                id="email"
                type="email"
                name="email"
                label="Adresse e-mail"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                autoFocus
              />
              <br />

              <Field
                component={TextField}
                id="firstName"
                name="firstName"
                label="Prénom"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="firstName"
              />
              <br />

              <Field
                component={TextField}
                id="lastName"
                name="lastName"
                label="Nom"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="lastName"
              />
              <br />

              <Field
                component={TextField}
                id="pwd"
                type="password"
                name="pwd"
                label="Mot de passe"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <br />

              <Field
                component={TextField}
                id="pwdCheck"
                type="password"
                name="pwdCheck"
                label="Confirmation mot de passe"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />
              <br />

              {error !== '' ?
                <Alert severity="error">{error}</Alert>
              : null}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >S'inscrire</Button>
            </Form>
          )}
        </Formik>

        <Typography component="p">
          <Link href="/" title="Revenir à la page de connexion" variant="button">
            J'ai déjà un compte
          </Link>
        </Typography>
      </div>
    </Container>
  );
}

export default Signin;
