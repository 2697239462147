import React, { useContext } from 'react';

import moment from 'moment';
import 'moment/locale/fr';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import AuthProvider, { authContext } from './contexts/AuthContext';

import Home from './components/base/Home';

import Login from './components/base/Login';
import Signin from './components/base/Signin';
import ForgotPassword from './components/base/ForgotPassword';
import ResetPassword from './components/base/ResetPassword';

import Profile from './components/user/Profile';
import Bookings from './components/user/Bookings';
import Packages from './components/user/Packages';
import Billings from './components/user/Billings';

import BillingsAdmin from './components/admin/BillingsAdmin';
import UsersAdmin from './components/admin/UsersAdmin';
import UserDetails from './components/admin/UserDetails';
import PackageDetails from './components/admin/PackageDetails';
import RoomsAdmin from './components/admin/RoomsAdmin';
import BookingRulesAdmin from './components/admin/BookingRulesAdmin';
import BookingsAdmin from './components/admin/BookingsAdmin';
import PackagesAdmin from './components/admin/PackagesAdmin';

import StoreProvider from './contexts/StoreContext';

import './App.css';


function LoginRoute({ children, ...rest }: any) {
  const auth = useContext(authContext);
  const userIsConnected = auth.auth && auth.auth.user.roles.indexOf('user') !== -1;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !userIsConnected ? (children) : (
          <Redirect
            to={{
              pathname: '/profile',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function UserRoute({ children, ...rest }: any) {
  const auth = useContext(authContext);
  const userIsConnected = auth.auth && auth.auth.user.roles.indexOf('user') !== -1;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userIsConnected ? (<Home>{children}</Home>) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AdminRoute({ children, ...rest }: any) {
  const auth = useContext(authContext);
  const userIsAdmin = auth.auth && auth.auth.user.roles.indexOf('admin') !== -1;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userIsAdmin ? (<Home>{children}</Home>) : (
          <Redirect
            to={{
              pathname: '/profile',
              state: { from: location, error: 'unauthorized' },
            }}
          />
        )
      }
    />
  );
}

function App() {

  moment.locale('fr');

  return (
    <AuthProvider>
      <Router>
        <Switch>
          {/* Login route */}
          <LoginRoute exact path="/">
            <Login />
          </LoginRoute>
          <LoginRoute path="/register">
            <Signin />
          </LoginRoute>
          <LoginRoute path="/forgotPassword">
            <ForgotPassword />
          </LoginRoute>
          <LoginRoute path="/resetPassword/:token">
            <ResetPassword />
          </LoginRoute>

          {/* User routes */}
          <StoreProvider>
            <UserRoute path="/profile">
              <Profile />
            </UserRoute>
            <UserRoute path="/bookings">
              <Bookings />
            </UserRoute>
            <UserRoute path="/packages">
              <Packages />
            </UserRoute>
            <UserRoute path="/billings">
              <Billings />
            </UserRoute>

            {/* Admin routes */}
            <AdminRoute exact path="/admin/users">
              <UsersAdmin />
            </AdminRoute>
            <AdminRoute exact path="/admin/users/:userId">
              <UserDetails />
            </AdminRoute>
            <AdminRoute path="/admin/users/:userId/packages/:packageId">
              <PackageDetails />
            </AdminRoute>
            <AdminRoute path="/admin/rooms">
              <RoomsAdmin />
            </AdminRoute>
            <AdminRoute path="/admin/bookingRules">
              <BookingRulesAdmin />
            </AdminRoute>
            <AdminRoute path="/admin/bookings">
              <BookingsAdmin />
            </AdminRoute>
            <AdminRoute path="/admin/packages">
              <PackagesAdmin />
            </AdminRoute>
            <AdminRoute path="/admin/billings">
              <BillingsAdmin />
            </AdminRoute>
          </StoreProvider>

        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
