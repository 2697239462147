import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { USERS_ADD_START, USERS_ADD_FAIL, USERS_ADD_SUCCESS, USERS_UPDATE_START, USERS_UPDATE_SUCCESS, USERS_UPDATE_FAIL, User } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface UserFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  existing?: User;
}

interface UserFormValues {
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
  pwdTmp: string;
  isAdmin: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserForm = (props: UserFormProps) => {
  const { onClose, existing, className, ...rest} = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const emptyValues: UserFormValues = {
    email: '',
    lastName: '',
    firstName: '',
    pwdTmp: '',
    isAdmin: false,
  };
  const initialValues: UserFormValues = existing ? {
    _id: existing._id,
    email: existing.email,
    firstName: existing.firstName,
    lastName: existing.lastName,
    pwdTmp: '',
    isAdmin: existing.roles.indexOf('admin') !== -1,
  } : emptyValues;

  const userMapper = (values: UserFormValues): any => {
    const { isAdmin, pwdTmp, ...reqValues} = values;
    const roles = isAdmin ? { roles: ['user', 'admin'] } : { roles: ['user'] };
    const password = pwdTmp !== '' ? { pwdTmp } : null;
    return Object.assign({}, reqValues, roles, password);
  }

  const creationHandler = async (values: UserFormValues) => {
    try {
      dispatch({type: USERS_ADD_START});
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + '/users',
        'POST',
        true,
        userMapper(values)
      );
      dispatch({type: USERS_ADD_SUCCESS, payload: userData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERS_ADD_FAIL, payload: err.message});
    }
  }

  const editionHandler = async (values: UserFormValues) => {
    try {
      dispatch({type: USERS_UPDATE_START});
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + '/users/' + values._id,
        'PUT',
        true,
        userMapper(values)
      );
      dispatch({type: USERS_UPDATE_SUCCESS, payload: userData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERS_UPDATE_FAIL, payload: err.message});
    }
  }

  const renderForm = ({ isSubmitting }: FormikProps<UserFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title={existing ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'} />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field type="hidden" name="_id" />
              <Field
                component={TextField}
                id="email"
                name="email"
                label="Adresse e-mail"
                required
                fullWidth
                autoComplete="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                id="firstName"
                name="firstName"
                label="Prénom"
                required
                fullWidth
                autoComplete="fname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                id="lastName"
                name="lastName"
                label="Nom"
                required
                fullWidth
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="isAdmin"
                Label={{ label: 'Administrateur ?' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                type="password"
                id="pwdTmp"
                name="pwdTmp"
                label="Mot de passe temporaire"
                helperText={existing ? 'Laisser vide pour ne pas le modifier' : ''}
                required={existing ? false : true}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Modifier</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.users.formError !== '' ?
            <Alert severity="error">{state.users.formError}</Alert> : null
          }
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {};
        if (!values.lastName) {
          errors.lastName = 'Champ nom requis';
        } else if (!values.firstName) {
          errors.firstName = 'Champ prénom requis';
        } else if (!values.email) {
          errors.email = 'E-mail requis';
        } else if (!values._id && !values.pwdTmp) {
          errors.pwdTmp = 'Mot de passe requis';
        }
        return errors;
      }}
      onSubmit={(values) => values._id ? editionHandler(values) : creationHandler(values)}
    >
      {renderForm}
    </Formik>
  );
}

export default UserForm;
