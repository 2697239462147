import { Action, UserPackagesState, USERPKG_GET_START, USERPKG_GET_SUCCESS, USERPKG_GET_FAIL, USERPKG_ADD_START, USERPKG_ADD_SUCCESS, USERPKG_ADD_FAIL, USERPKG_UPDATE_START, USERPKG_UPDATE_SUCCESS, USERPKG_UPDATE_FAIL, userPackageMapper } from '../types';

const userPackagesReducer = (state: UserPackagesState, action: Action): UserPackagesState => {
  switch (action.type) {
    case USERPKG_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case USERPKG_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(userPackageMapper(action.payload)),
        formError: '',
      };
    case USERPKG_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: '',
      };
    case USERPKG_GET_START:
      return {
        ...state,
        isLoading: true,
        loadingError: '',
      };
    case USERPKG_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload.map(userPackageMapper)),
        loadingError: '',
      };
    case USERPKG_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case USERPKG_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case USERPKG_UPDATE_SUCCESS:
      const updatedUserPack = userPackageMapper(action.payload);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(userPack => {
          return (userPack._id === updatedUserPack._id) ? updatedUserPack : userPack;
        }),
        formError: '',
      };
    case USERPKG_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    default:
      return state;
  }
}

export default userPackagesReducer;
