import { Action, BKG_GET_START, BookingsState, BKG_ADD_START, BKG_DELETE_START, BKG_GET_SUCCESS, BKG_GET_FAIL, BKG_ADD_SUCCESS, BKG_ADD_FAIL, BKG_UPDATE_START, BKG_UPDATE_SUCCESS, BKG_UPDATE_FAIL, BKG_DELETE_SUCCESS, BKG_DELETE_FAIL, bookingMapper } from "../types";

const bookingsReducer = (state: BookingsState, action: Action): BookingsState => {
  switch (action.type) {
    case BKG_ADD_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BKG_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.concat(bookingMapper(action.payload)),
        formError: '',
      };
    case BKG_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BKG_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BKG_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.map(bookingMapper),
        error: '',
      };
    case BKG_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case BKG_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case BKG_UPDATE_SUCCESS:
      const updatedBooking = bookingMapper(action.payload);
      return {
        ...state,
        isLoading: false,
        data: state.data.map(booking => {
          return (booking._id === updatedBooking._id) ? updatedBooking : booking;
        }),
        formError: '',
      };
    case BKG_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    case BKG_DELETE_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case BKG_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(type => type._id !== (action.payload as string)),
      };
    case BKG_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    default:
      return state;
  }
}

export default bookingsReducer;
