import React, { useContext } from 'react';

import { Formik, Form, Field, FormikProps } from 'formik';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { BILLS_UPDATE_START, BILLS_UPDATE_SUCCESS, BILLS_UPDATE_FAIL, Billing } from '../../types';
import { apiRequest } from '../../utils/Helpers';
import { storeContext } from '../../contexts/StoreContext';


interface BillingGiftFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
  billing: Billing;
}

interface BillingFormValues {
  _id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const BillingGiftForm = (props: BillingGiftFormProps) => {
  const { onClose, billing, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);

  const initialValues: BillingFormValues = {
    _id: billing._id,
  };

  const userNameFrom = (billing: Billing) => {
    const user = state.users.data.find(user => user._id === billing.user);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  const giftHandler = async () => {
    try {
      dispatch({type: BILLS_UPDATE_START});
      const billData = await apiRequest(
        process.env.REACT_APP_API_URL + `/billings/${billing._id}/gift`,
        'PUT',
        true
      );
      dispatch({type: BILLS_UPDATE_SUCCESS, payload: billData});
      onClose(true);
    } catch (err: any) {
      dispatch({type: BILLS_UPDATE_FAIL, payload: err.message});
    }
  }

  const renderForm = ({ isSubmitting }: FormikProps<BillingFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Offrir la commande" />
        <Divider />

        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Field type="hidden" name="_id" />
              <p>Je souhaite offrir {billing.subject} d'un montant de {billing.amount.toFixed()} € à {userNameFrom(billing)}</p>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Confirmer</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => onClose()}
          >Annuler</Button>
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <div>
      {state.billings.formError !== '' ? <p>{state.billings.formError}</p> : null}
      <Formik
        initialValues={initialValues}
        onSubmit={() => giftHandler()}
      >
        {renderForm}
      </Formik>
    </div>
  );
}

export default BillingGiftForm;
