import React, { useReducer, createContext, useEffect } from 'react';

import { Store, ROOM_GET_START, ROOM_GET_SUCCESS, ROOM_GET_FAIL, BKGRULE_GET_START, BKGRULE_GET_SUCCESS, BKGRULE_GET_FAIL, PKG_GET_START, PKG_GET_SUCCESS, PKG_GET_FAIL } from '../types';
import storeReducer from '../reducers/StoreReducer';
import { apiRequest } from '../utils/Helpers';

const initialState: Store = {
  user: {
    isLoading: true,
    data: undefined,
    error: '',
    formError: '',
  },
  rooms: {
    isLoading: true,
    data: [],
    error: '',
    formError: '',
  },
  bookingRules: {
    isLoading: true,
    data: [],
    error: '',
    formError: '',
  },
  users: {
    isLoading: true,
    data: [],
    loadingError: '',
    formError: '',
  },
  packages: {
    isLoading: true,
    data: [],
    error: '',
    formError: '',
  },
  bookings: {
    isLoading: true,
    data: [],
    error: '',
    formError: '',
  },
  billings: {
    isLoading: true,
    data: [],
    error: '',
    formError: '',
  },
  userPackages: {
    isLoading: true,
    data: [],
    loadingError: '',
    formError: '',
  },
};

export const storeContext = createContext<[Store, any]>([
  initialState,
  () => {},
]);

const { Provider } = storeContext;

const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        dispatch({type: ROOM_GET_START});
        const roomsData = await apiRequest(
          process.env.REACT_APP_API_URL + '/rooms/',
          'GET',
          true,
        );
        dispatch({type: ROOM_GET_SUCCESS, payload: roomsData});
      } catch (err) {
        dispatch({type: ROOM_GET_FAIL, payload: err.message});
      }
    }

    fetchRooms();
  }, [dispatch]);

  useEffect(() => {
    const fetchRules = async () => {
      try {
        dispatch({type: BKGRULE_GET_START});
        const rulesData = await apiRequest(
          process.env.REACT_APP_API_URL + '/bookingRules/',
          'GET',
          true,
        );
        dispatch({type: BKGRULE_GET_SUCCESS, payload: rulesData});
      } catch (err) {
        dispatch({type: BKGRULE_GET_FAIL, payload: err.message});
      }
    }

    fetchRules();
  }, [dispatch]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        dispatch({type: PKG_GET_START});
        const packagesData = await apiRequest(
          process.env.REACT_APP_API_URL + '/packages/',
          'GET',
          true,
        );
        dispatch({type: PKG_GET_SUCCESS, payload: packagesData});
      } catch (err) {
        dispatch({type: PKG_GET_FAIL, payload: err.message});
      }
    }

    fetchPackages();
  }, [dispatch]);

  return (
    <Provider value={[state, dispatch]}>
      {children}
    </Provider>
  );
}

export default StoreProvider;
