import { Action, User, UserState, USER_GET_START, USER_GET_SUCCESS, USER_GET_FAIL, USER_UPDATE_START, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL } from "../types";

const userReducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case USER_GET_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as User),
        error: '',
      };
    case USER_GET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: (action.payload as string),
      };
    case USER_UPDATE_START:
      return {
        ...state,
        isLoading: true,
        formError: '',
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: (action.payload as User),
        formError: '',
      };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        formError: (action.payload as string),
      };
    default:
      return state;
  }
}

export default userReducer;
