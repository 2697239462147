import React, { useContext } from 'react';
import moment from 'moment';

import { Formik, Form, Field, FormikProps } from 'formik';
import { Select } from 'formik-material-ui';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { USERPKG_ADD_START, USERPKG_ADD_SUCCESS, USERPKG_ADD_FAIL } from '../../types';
import { authContext } from '../../contexts/AuthContext';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest, getStartOfToday } from '../../utils/Helpers';


interface PackageFormProps {
  className?: string;
  onClose: (success?: boolean) => void;
}

interface PackageFormValues {
  package: string,
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const PackageForm = (props: PackageFormProps) => {
  const { onClose, className, ...rest } = props;

  const classes = useStyles();
  const auth = useContext(authContext);
  const [state, dispatch] = useContext(storeContext);

  const initialValues: PackageFormValues = {
    package: '',
  };

  const creationHandler = async (values: PackageFormValues) => {
    try {
      dispatch({type: USERPKG_ADD_START});
      const userData = await apiRequest(
        process.env.REACT_APP_API_URL + `/users/${auth.auth?.user._id}/packages`,
        'POST',
        true,
        values
      );
      dispatch({type: USERPKG_ADD_SUCCESS, payload: userData});
      onClose(true);
    } catch (err) {
      dispatch({type: USERPKG_ADD_FAIL, payload: err.message});
    }
  }

  const packInfo = (packId: string) => {
    const pack = state.packages.data.find(pck => pck._id === packId);

    if (pack) {
      const validityDate = (pack.validity && pack.validity > 0) ?
        moment(getStartOfToday()).add(pack.validity, 'week').subtract(1, 'day').toDate() : undefined;
      return (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <InputLabel>Facturation</InputLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>Je devrais régler la somme de <strong>{pack.price.toFixed()} &euro; pour ce carnet</strong></p>
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputLabel>Validité</InputLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            {validityDate ? (
              <p>Ce carnet sera valide jusqu'au {validityDate.toLocaleDateString()} inclus</p>
            ) : (
              <p>Sans échéance</p>
            )}
          </Grid>
        </React.Fragment>
      );
    }
  }

  const renderForm = ({ isSubmitting, values}: FormikProps<PackageFormValues>) => (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Form>
        <CardHeader title="Commande de carnet" />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel htmlFor="package">Je souhaite commander le carnet</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                component={Select}
                id="package"
                name="package"
                native
                required
              >
                <option key="pack_void" value="">Sélectionner le type de carnet</option>
                {state.packages.data.map((pack, idx) =>
                  <option key={`pack_${idx.toFixed()}`} value={pack._id}>{pack.name}</option>
                )}
              </Field>
            </Grid>

            {values.package !== '' ? packInfo(values.package) : null}
          </Grid>
        </CardContent>

        <Divider />
        <CardActions>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >Commander</Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >Annuler</Button>

          {state.user.formError !== '' ?
            <Alert severity="error">{state.user.formError}</Alert> : null}
        </CardActions>
      </Form>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {};
        if (!values.package) {
          errors.pack = 'Type de carnet requis';
        }
        return errors;
      }}
      onSubmit={(values) => creationHandler(values)}
    >
      {renderForm}
    </Formik>
  );
}

export default PackageForm;
