import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { User, USERS_DELETE_START, USERS_DELETE_SUCCESS, USERS_DELETE_FAIL, USERS_GET_START, USERS_GET_SUCCESS, USERS_GET_FAIL } from '../../types';
import { storeContext } from '../../contexts/StoreContext';
import { apiRequest } from '../../utils/Helpers';


interface UsersListProps {
  className?: string;
  onDisplayUserForm: (user?: User) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
}));

const UsersList = (props: UsersListProps) => {
  const { onDisplayUserForm, className, ...rest } = props;

  const classes = useStyles();
  const [state, dispatch] = useContext(storeContext);
  const [successDelete, setSuccessDelete] = useState(false);

  const [ui, setUi] = useState({
    displayDeleteModal: false,
    userToDelete: '',
    userDeleteSuccess: false,
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch({type: USERS_GET_START});
        const usersData = await apiRequest(
          process.env.REACT_APP_API_URL + '/users/',
          'GET',
          true,
        );
        dispatch({type: USERS_GET_SUCCESS, payload: usersData});
      } catch (err: any) {
        dispatch({type: USERS_GET_FAIL, payload: err.message});
      }
    }

    fetchUsers();
  }, [dispatch]);

  const userDeletionHandler = async (id: string) => {
    try {
      setSuccessDelete(false);
      dispatch({type: USERS_DELETE_START});
      await apiRequest(
        process.env.REACT_APP_API_URL + '/users/' + id,
        'DELETE',
        true
      );
      dispatch({type: USERS_DELETE_SUCCESS, payload: id});
      setSuccessDelete(true);
    } catch (err: any) {
      dispatch({type: USERS_DELETE_FAIL, payload: err.message});
    }
  }

  const handleOpenDialog = (id: string) => {
    setUi({
      displayDeleteModal: true,
      userToDelete: id,
      userDeleteSuccess: false,
    });
  }

  const handleClosedDialog = (confirmed: boolean) => {
    if (confirmed) {
      userDeletionHandler(ui.userToDelete);
    }
    setUi({
      displayDeleteModal: false,
      userToDelete: '',
      userDeleteSuccess: confirmed,
    });
  }

  const renderTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Prénom</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.users.data.map(user => {
          return (<TableRow key={user._id}>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              <ButtonGroup color="primary" variant="outlined" size="small">
                <Button component={Link} to={`/admin/users/${user._id}`}>Détails</Button>
                <Button type="button" onClick={() => { onDisplayUserForm(user); setSuccessDelete(false); }}>
                  Modifier
                </Button>
                <Button type="button" onClick={() => handleOpenDialog(user._id)}>
                  Supprimer
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>);
        })}
      </TableBody>
    </Table>
  );

  const renderList = () => {
    if (state.users.isLoading) {
      return (
        <Alert severity="info">Chargement en cours</Alert>
      );
    } else {
      if (state.users.loadingError !== '') {
        return (
          <Alert severity="error">
            Erreur lors du chargements des utilisateurs<br />
            {state.users.loadingError}
          </Alert>
        );
      } else if (state.users.data.length === 0) {
        return (
          <Alert severity="info">Aucun utilisateur enregistré</Alert>
        );
      } else {
        return renderTable();
      }
    }
  }

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Gestion des utilisateurs" action={
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled
            onClick={() => { onDisplayUserForm(); setSuccessDelete(false); }}
          >Ajouter un utilisateur</Button>
        } />
        <Divider />

        <CardContent>
          {renderList()}
        </CardContent>

        {successDelete ? (<React.Fragment>
          <Divider />
          <CardActions>
            <Alert severity="success">Utilisateur supprimé avec succès</Alert>
          </CardActions>
        </React.Fragment>) : null}
      </Card>

      <Dialog
        open={ui.displayDeleteModal}
        onClose={() => handleClosedDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        >
        <DialogTitle id="confirm-dialog-title">Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            La suppression d'un utilisateur entraînera la suppression de toutes ses réservations, ses carnets ainsi que de ses commandes (éléments de facturation).<br />
            Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClosedDialog(false)}>Non</Button>
          <Button onClick={() => handleClosedDialog(true)} color="primary">Confirmer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default UsersList;
